import React from "react";
import Hero from "./hero";
import IndustryRecognitionBenefits from "./IndustryRecognition";
import AssessmentBox from "./AboutThisNvqAssessment";
import KeyFeaturesBox from "./KeyFeaturesBox";
import WhyChooseM2HSECarousel from "./WhyM2hse";
import FAQ from "./Faqs";
import RelatedCourses from "./RelatedCourses";
import CoursePricing from "./CoursePricing";
import EntryRequirement from "./EntryRequirements";
import HowItWorks from "./HowItsWork";
import FaqAndContact from "./FaqsAndContactForm";
import Certification from "./Certification";

const IndustrialStorageSystemsMaintenance = () => {
  return (
    <div>
      <Hero />
      <CoursePricing />
      <EntryRequirement />
      <FAQ />
      <HowItWorks />
      <AssessmentBox />
      <Certification />
      <IndustryRecognitionBenefits />
      <KeyFeaturesBox />
      <WhyChooseM2HSECarousel />
      <FaqAndContact />
      {/* <RelatedCourses /> */}
    </div>
  );
};

export default IndustrialStorageSystemsMaintenance;
