import React, { useEffect, useState } from "react";
import axios from "axios";
import citbLogo from "../../assets/CitbPage/citb_logo.png";
import image from "../../assets/CitbPage/citb-course.webp";
const CitbCourses = () => {
  const [coursesData, setCoursesData] = useState([]);

  useEffect(() => {
    axios
      .get("product/filter", {
        params: {
          filter: "productCategory",
          equal: "65e973a001d6a06a746d5d25",
        },
      })
      .then((response) => {
        setCoursesData(response.data.result);
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
      });
  }, []);

  return (
    <div className="bg-[#ffffff] mt-28 py-12">
      <div className="mx-auto px-6 sm:px-8 lg:px-16">
        {/* Top Section */}
        <div className="text-center mb-12">
          <img src={citbLogo} alt="CITB Logo" className="mx-auto mb-4" />
          <h1 className="text-3xl lg:text-3xl font-bold text-[#FF3131] mb-2">
            CITB Courses
          </h1>
          <p className="text-xl text-gray-500 opacity-80">
            Browse through our CITB-accredited courses to enhance your career in
            site management.
          </p>
        </div>

        {/* Course Cards Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {coursesData.map((course) => (
            <div
              key={course._id}
              className="bg-white rounded-lg shadow-md transform hover:scale-105 transition-transform duration-300 ease-in-out cursor-pointer"
              onClick={() => {
                window.location.href = course.detailsPage;
              }}>
              <div className="relative">
                <img
                  src={course.image || image}
                  alt={course.name}
                  className="w-full h-50 object-fit rounded-t-lg"
                />
                <div className="absolute bg-gray-900 h-full w-full top-0 opacity-70 rounded-t-lg"></div>
                <div className="absolute top-0 left-0 right-0 bg-white bg-opacity-90 p-2">
                  <h3 className="text-lg text-[#FF3131] font-semibold truncate">
                    {course.name}
                  </h3>
                </div>
              </div>

              <div className="p-4 space-y-2">
                <p className="text-gray-700 text-sm line-clamp-3">
                  {course.description}
                </p>

                <div className="flex justify-between items-center">
                  <p className="text-lg font-semibold text-[#FF3131]">
                    £{course.price} + VAT
                  </p>

                  <button
                    onClick={() => {
                      window.location.href = course.detailsPage;
                    }}
                    className="px-3 py-1 bg-[#FF3131] text-white font-semibold rounded-md hover:bg-red-700 transition-colors">
                    Learn more
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CitbCourses;
