import axios from "axios";
import React, { useState } from "react";
import { useToast } from "../../../components/Toast/toast";
import { useMutation } from "react-query";

const FaqAndContact = () => {
  const faqs = [
    {
      question: "What payment methods do you accept for payment plans?",
      answer:
        "We accept credit/debit cards, PayPal, GooglePay, ApplePay, and bank transfers for payment plans.",
    },
    {
      question: "How do I sign up for a payment plan?",
      answer:
        "You can sign up for a payment plan during the checkout process. Simply choose your preferred plan and add it to the cart, and you'll be guided through the setup.",
    },
    {
      question: "Is there a down payment required to start a payment plan?",
      answer:
        "Yes, the first payment is required upfront to activate the payment plan.",
    },
    {
      question: "Can I choose the duration of my payment plan?",
      answer:
        "Our payment plans offer fixed options: 2, 3, or 6 installments over a 6-month period.",
    },
    {
      question:
        "Are there any additional fees or interest with the payment plan?",
      answer:
        "No, there are no additional fees or interest. All plans are at no extra cost.",
    },
    {
      question: "What happens if I miss a payment?",
      answer:
        "If you miss a payment, you will be notified, and we will work with you to resolve the issue. Please note that reasonable delays in payments may delay the completion of your NVQ.",
    },
    {
      question: "Can I pay off the remaining balance early without penalties?",
      answer:
        "Yes, you can pay off the remaining balance at any time without any penalties.",
    },
    {
      question: "How do I update my payment method or billing information?",
      answer:
        "You can update your payment method or billing information by contacting our support team.",
    },
    {
      question: "Will I receive a reminder before each instalment is due?",
      answer:
        "Yes, you will receive a reminder before each instalment is due, ensuring you’re prepared for upcoming payments.",
    },
    {
      question: "What happens if I want to cancel my payment plan?",
      answer:
        "If you wish to cancel your payment plan, please contact us. However, the balance of the amount will still need to be settled based on the agreement.",
    },
  ];
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleFaq = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const useCreatePerson = async ({
    firstname,
    lastname,
    email,
    phone,
    message,
  }) => {
    const response = await axios.post("people/create", {
      firstname,
      lastname,
      email,
      phone,
      message,
    });
    return response.data;
  };

  const handleCombinedSubmit = async (e) => {
    e.preventDefault();

    const firstname = e.target.elements.firstname.value;
    const lastname = e.target.elements.lastname.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
    const message = e.target.elements.message.value;

    try {
      await mutate({ firstname, lastname, email, phone, message });
      addToast(
        "We have received your query, please rest assured our team will get back to you",
        "success"
      );
      e.target.reset();
    } catch (error) {
      console.error(error);
      addToast("An error occurred. Please try again later.", "error");
    }
  };

  const { mutate } = useMutation(useCreatePerson);
  const { addToast } = useToast();

  return (
    <div className="bg-[#0C1524] text-white p-8 md:p-12 lg:px-20 lg:py-16">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* FAQ Section */}
        <div>
          <h2 className="text-2xl md:text-3xl text-[#FF3131] font-semibold mb-6">
            FAQ'S
          </h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="bg-[#1B2438] p-4 rounded-xl shadow-inner shadow-gray-500">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFaq(index)}>
                  <h3 className="text-lg font-medium"> {faq.question}</h3>
                  <span>{expandedIndex === index ? "-" : "+"}</span>
                </div>
                {expandedIndex === index && (
                  <p className="mt-4 text-gray-300">Ans: {faq.answer}</p>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Contact Form Section */}
        <div>
          <h2 className="text-2xl md:text-3xl font-semibold mb-6 ">
            Get in Touch
          </h2>
          <form
            onSubmit={handleCombinedSubmit}
            className="space-y-4 bg-[#1B2438] rounded-xl shadow-inner shadow-gray-500 p-6">
            {/* Name */}
            <div>
              <label className="block text-sm mb-2" htmlFor="firstname">
                First Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="firstname"
                id="firstname"
                placeholder="Your first name"
                required
                className="w-full p-3 bg-[#1B2438] border border-gray-600 rounded-xl text-white"
              />
            </div>

            <div>
              <label className="block text-sm mb-2" htmlFor="lastname">
                Last Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="lastname"
                id="lastname"
                placeholder="Your last name"
                required
                className="w-full p-3 bg-[#1B2438] border border-gray-600 rounded-xl text-white"
              />
            </div>

            {/* Phone */}
            <div>
              <label className="block text-sm mb-2" htmlFor="phone">
                Phone<span className="text-red-500">*</span>
              </label>
              <input
                type="tel"
                name="phone"
                id="phone"
                placeholder="Your phone number"
                required
                className="w-full p-3 bg-[#1B2438]  border border-gray-600 rounded-xl text-white"
              />
            </div>

            {/* Email */}
            <div>
              <label className="block text-sm mb-2" htmlFor="email">
                Email<span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Your email id"
                required
                className="w-full p-3 bg-[#1B2438] border border-gray-600 rounded-xl text-white"
              />
            </div>

            {/* Message */}
            <div>
              <label className="block text-sm mb-2" htmlFor="message">
                Message (Optional)
              </label>
              <textarea
                name="message"
                id="message"
                placeholder="Please drop your query, we would love to help you"
                className="w-full p-3 bg-[#1B2438] border border-gray-600 rounded-xl text-white"
                rows="4"></textarea>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-[#188E00] shadow-inner shadow-white hover:bg-green-900 text-white py-3 rounded-xl">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FaqAndContact;
