import React, { useEffect, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import KlarnaLogo from "../../../assets/klarna_logo/Klarna.svg";
import axios from "axios";
import { useCart } from "../../../CartContext";

const PricingDropdown = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const url = window.location.pathname;
  const detailPage = url.split("/").pop();
  const { addToCart } = useCart();

  const [data, setData] = useState(null);
  useEffect(() => {
    axios
      .get(`product/filter`, {
        params: {
          filter: "detailsPage",
          equal: `/${detailPage}`,
        },
      })
      .then((result) => {
        setData(result);
        // console.log(result.data.result);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleAddToCart = (product) => {
    product.isEmi = false;
    addToCart(product);
  };

  return (
    <div className="bg-[#0C1524] text-gray-800 rounded-t-xl max-w-4xl mx-auto mt-10 p-8 relative">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row items-center justify-between gap-6 border-b pb-6">
        <div className="text-center sm:text-left">
          <h2 className="text-3xl font-bold text-white">
            £{data?.data?.result[0]?.price} + VAT
          </h2>
          <button
            onClick={() => {
              handleAddToCart(data?.data?.result[0]);
            }}
            className="bg-[#FF3131] text-white py-2 px-8 mt-4 rounded-lg text-base font-medium hover:bg-[#ff4d4d] transition-all"
          >
            Add to Cart
          </button>
        </div>

        <div className="flex flex-col relative">
          {/* Klarna Section */}
          <div className="text-center">
            <img
              src={KlarnaLogo}
              alt="Klarna Logo"
              className="mx-auto w-[100px]"
            />
            <p className="mt-2 text-sm text-white">available on checkout</p>
          </div>
        </div>

        <div className="flex items-center space-x-3">
          <p className="text-base text-white font-medium">
            Course Specification
          </p>
          <a
            href="/course-specification.pdf"
            className="text-white hover:text-[#FF3131] transition-all"
            download
          >
            <AiOutlineDownload size={28} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PricingDropdown;
