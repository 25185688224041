import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./customTheme";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom/dist";
import { QueryClient, QueryClientProvider } from "react-query";
import { API_BASE_URL } from "./config/serverApiConfig";
import axios from "axios";
import { CartProvider } from "./CartContext.js";
import { ToastProvider } from "./components/Toast/toast.jsx";
import Loader from "./components/Loader/Loader.jsx";
axios.defaults.baseURL = API_BASE_URL;
axios.defaults.headers = { "ngrok-skip-browser-warning": true };
const Home = lazy(() => import("./screens/Home"));
const CartScreen = lazy(() => import("./screens/Cart_Screen"));
const LevelOneGreenCard = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_1/NvqGreenCscsCardSlots/NvqGreenCscsCardSlots.jsx"));
const CitbCourses = lazy(() => import("./components/CITB_Courses/CitbCardsScreen"));
const NvqProducts = lazy(() => import("./components/Nvq_Product_page/index.jsx"));
const AboutUs = lazy(() => import("./screens/About_Us/index.jsx"));
const CSCSCardPage = lazy(() => import("./screens/CscsCards_screen/index.jsx"));
const PrivacyPolicy = lazy(() => import("./screens/Privacy_Policy/index.jsx"));
const TermsAndConditionsPage = lazy(() => import("./screens/Terms_And_Condition_Screen/index.jsx"));
const CookiesPolicyPage = lazy(() => import("./screens/Cookies_Policy/index.jsx"));
const ContactPage = lazy(() => import("./screens/Contact_Us/index.jsx"));
const SsstsCourse = lazy(() => import("./screens/CITB_Courses/SsstsCourses/Sssts_Course.jsx"));
const SsstsCourseDetail = lazy(() => import("./screens/CITB_Courses/SsstsCourses/SsstsDetails.jsx"));
const SsstsRefresherCourse = lazy(() => import("./screens/CITB_Courses/SsstsRefresher/SsstsRefresherCourse.jsx"));
const SmstsRefresherCourseDetail = lazy(() => import("./screens/CITB_Courses/SmstsRefresher/SmstsRefresherDetails.jsx"));
const SmstsCourse = lazy(() => import("./screens/CITB_Courses/SmstsCourses/Smsts_Course.jsx"));
const SmstsCourseDetail = lazy(() => import("./screens/CITB_Courses/SmstsCourses/SmstsDetails.jsx"));
const SmstsRefresherCourse = lazy(() => import("./screens/CITB_Courses/SmstsRefresher/SmstsRefresherCourse.jsx"));
const CustomerDetails = lazy(() => import("./screens/Cart_Screen/CustomerDetails.jsx"));
const ConstructionSeniorManagement = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_7/ConstructionSeniorManagement.jsx"));
const OhsLevel3 = lazy(() => import("./screens/NVQ_Courses/NVQ_Health_and_Safety/OHS_Level3/OhsLevel3.jsx"));
const OhsLevel6 = lazy(() => import("./screens/NVQ_Courses/NVQ_Health_and_Safety/OHS_Level6/OhsLevel6.jsx"));
const OhsLevel7 = lazy(() => import("./screens/NVQ_Courses/NVQ_Health_and_Safety/OHS_Level7/OhsLevel7.jsx"));
const ResidentiaDevelopment = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_6/ResidentialDevelopment/ResidentialDevelopment.jsx"));
const HealthSafetyConsultancy = lazy(() => import("./screens/Business_Solutions/BusinessSolutions.jsx"));
const TraditionalandHeritageBuilding = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_6/TraditionalandHeritageBuilding/TraditionalandHeritageBuilding.jsx"));
const Tunnelling = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_6/Tunnelling/Tunnelling.jsx"));
const Retrofit = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_6/Retrofit/Retrofit.jsx"));
const ContractingOperationsManagement = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_6/ContractingOperationsManagement/ContractingOperationsManagement.jsx"));
const CivilandBuildingEngineering = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_6/CivilandBuildingEngineering/CivilandBuildingEngineering.jsx"));
const Demolition = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_6/Demolition/Demolition.jsx"));
const SeniorSiteInspection = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_6/SeniorSiteInspection/SeniorSiteInspection.jsx"));
const AwardLevel5 = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_5/AwardLevel5/AwardLevel5.jsx"));
const PlanningLifts = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_5/PlanningLifts/PlanningLifts.jsx"));
const AwardLevel3 = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_Awards/Level3Award.jsx"));
const SsstsRefresherCourseDetail = lazy(() => import("./screens/CITB_Courses/SsstsRefresher/SsstsRefresherDetails.jsx"));
const CheckoutForm = lazy(() => import("./screens/Cart_Screen/checkout.jsx"));
const Return = lazy(() => import("./screens/Cart_Screen/return.jsx"));
const RefundPolicy = lazy(() => import("./screens/RefundPolicy/RefundPolicy.jsx"));
const HighwaysMaintenanceandRepair = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_6/HighwaysMaintenanceandRepair/HighwaysMaintenanceandRepair.jsx"));
const DemolitionLevel4 = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_4/Demolition/Demolition.jsx"));
const RetrofitLevel4 = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_4/Retrofit/Retrofit.jsx"));
const TraditionalandHeritageBuildingLevel4 = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_4/TraditionalandHeritageBuilding/TraditionalandHeritageBuilding.jsx"));
const ResidentiaDevelopmentLevel4 = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_4/ResidentialDevelopment/ResidentialDevelopment.jsx"));
const HighwaysMaintenanceandRepairLevel4 = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_4/HighwaysMaintenanceandRepair/HighwaysMaintenanceandRepair.jsx"));
const CivilandBuildingEngineeringLevel4 = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_4/CivilandBuildingEngineering/CivilandBuildingEngineering.jsx"));
const SupervisingLiftsLevel4 = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_4/SupervisingLifts/SupervisingLifts.jsx"));
const AwardLevel4 = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_4/AwardLevel4/AwardLevel4.jsx"));
const TunnellingLevel4 = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_4/Tunnelling/Tunnelling.jsx"));
const PipeworkComponents = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/InstallationOfPipeworkComponentsConstruction/InstallationOfPipeworkComponentsConstruction.jsx"));
const MovingLoadsConstruction = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/MovingLoadsConstrutions/MovingLoadsConstruction.jsx"));
const PlantMaintenance = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/PlantMaintenanceConstrution/PlantMaintenance.jsx"));
const ShoringAndPipeLaying = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/ShoringAndPipeLaying/ShoringAndPipeLaying.jsx"));
const WeldingConstruction = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/WeldingConstruction/WeldingConstruction.jsx"));
const Surveying = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/ConstructionContractingOperations/Surveying/Surveying.jsx"));
const SiteTechnicalSupport = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/WeldingConstruction/WeldingConstruction.jsx"));
const TemporarySuspendedAccess = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/WeldingConstruction/WeldingConstruction.jsx"));
const Steeplejacking = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/WeldingConstruction/WeldingConstruction.jsx"));
const ScaffoldingAndOffshore = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/AccessingOperationAndRigging/ScaffoldingAndOffshore/ScaffoldingAndOffshore.jsx"));
const LightingProtectiveSystem = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/AccessingOperationAndRigging/LightingProtectiveSystem/LightingProtectiveSystem.jsx"));
const LightingProtectionEngineer = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/AccessingOperationAndRigging/LightingProtectionEngineer/LightingProtectionEngineer.jsx"));
const RainScreenWallCladdingSystem = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/CladdingOperations/RainScreenWallCladdingSystem/RainScreenWallCladdingSystem.jsx"));
const RoofAndWallSheeting = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/CladdingOperations/RoofAndWallSheeting/RoofAndWallSheeting.jsx"));
const Buying = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/ConstructionContractingOperations/Buying/Buying.jsx"));
const DesignCoOrdinator = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/ConstructionContractingOperations/DesignCoOrdinator/DesignCoOrdinator.jsx"));
const Estimating = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/ConstructionContractingOperations/Estimating/Estimating.jsx"));
const General = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/ConstructionContractingOperations/General/General.jsx"));
const Planning = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/ConstructionContractingOperations/Planning/Planning.jsx"));
const DecorativeFinishing = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DecorativeFinishing/DecorativeFinishing.jsx"));
const DemolitionChargehand = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/Demolition/DemolitionChargehand/DemolitionChargehand.jsx"));
const DemolitionOperative = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/Demolition/DemolitionOperative/DemolitionOperative.jsx"));
const DemolitionPlant = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/Demolition/DemolitionPlant/DemolitionPlant.jsx"));
const ExternalWallInsulationBoarder = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/ExternalWallInsulationBoarder/ExternalWallInsulationBoarder.jsx"));
const RoomInRoof = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/RoomInRoof/RoomInRoof.jsx"));
const ParkHomes = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/ParkHomes/ParkHomes.jsx"));
const InternalInsulationWalls = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/InternalInsulationWalls/InternalInsulationWalls.jsx"));
const InsulatingFramedSections = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/InsulatingFramedSections/InsulatingFramedSections.jsx"));
const HybridWall = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/HybridWall/HybridWall.jsx"));
const ExternalWallInsulationFinisher = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/ExternalWallInsulationFinisher/ExternalWallInsulationFinisher.jsx"));
const DiplomaInWallAndFloorTiling = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWallAndFloorTiling/DiplomaInWallAndFloorTiling.jsx"));
const RoofSlater = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInRoofingOccupation/RoofSlater/index.jsx"));
const RoofSlaterAndTiler = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInRoofingOccupation/RoofSlaterAndTiler/index.jsx"));
const RoofTiler = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInRoofingOccupation/RoofTiler/index.jsx"));
const AwardLevel1 = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_1/Level1Award.jsx"));
const ExternalWallInsulationBoarderAndFinisher = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/ExternalWallInsulationFinisher/ExternalWallInsulationFinisher.jsx"));
const ComplexSuspendedCeilingSystems = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInteriorSystem/ComplexSuspendedCeilingSystems/ComplexSuspendedCeilingSystems.jsx"));
const ServiceOperablePartitionWalls = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInteriorSystem/ServiceOperablePartitionWalls/ServiceOperablePartitionWalls.jsx"));
const StretchedCeilingFixing = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInteriorSystem/StretchedCeilingFixing/StretchedCeilingFixing.jsx"));
const PlasteringSolid = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInPlastering/PlasteringSolid/PlasteringSolid.jsx"));
const PlasteringFibrous = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInPlastering/PlasteringFibrous/PlasteringFibrous.jsx"));
const DiplomaInPlantInstallationsTowerCrane = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInPlantInstallationsTowerCrane/DiplomaInPlantInstallationsTowerCrane.jsx"));
const DiplomaInPlantInstallationsHoist = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInPlantInstallationsHoist/DiplomaInPlantInstallationsHoist.jsx"));
const DiplomaInTrowelOccupation = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInTrowelOccupation/DiplomaInTrowelOccupation.jsx"));
const DiplomaInStonemasonryMemorialMasonry = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInStonemasonryMemorialMasonry/DiplomaInStonemasonryMemorialMasonry.jsx"));
const DiplomaInStonemasonryBankerMasonry = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInStonemasonryBankerMasonry/DiplomaInStonemasonryBankerMasonry.jsx"));
const ArchitecturalJoinery = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/ArchtitecturalJoinery/ArchtitecturalJoinery.jsx"));
const ShoplifittingBenchWork = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/ShoplifittingBenchWork/ShoplifittingBenchWork.jsx"));
const ShoplifittingSiteWork = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/ShoplifittingSiteWork/ShoplifittingSiteWork.jsx"));
const SiteCarpentry = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/SiteCarpentry/SiteCarpentry.jsx"));
const StructurePostAndBeam = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/StructurePostAndBeam/StructurePostAndBeam.jsx"));
const TimberFrameErection = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/TimberFrameErection/TimberFrameErection.jsx"));
const WheelWrighting = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/WheelWrighting/WheelWrighting.jsx"));
const Formwork = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/Formwork/Formwork.jsx"));
const HeritageSkillsFullySupportedLeadAndHardMetalRoofing = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/HeritageSkillsFullySupportedLeadAndHardMetalRoofing/HeritageSkillsFullySupportedLeadAndHardMetalRoofing.jsx"));
const HeritageSkillsMason = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/HeritageSkillsMason/HeritageSkillsMason.jsx"));
const HeritageSkillsRoofSlattingAndTilingOccupations = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/HeritageSkillsRoofSlattingAndTilingOccupations/HeritageSkillsRoofSlattingAndTilingOccupations.jsx"));
const HeritageSkillsWoodOccupations = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/HeritageSkillsWoodOccupations/HeritageSkillsWoodOccupations.jsx"));
const MachineryMaintenance = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/MachineryMaintenance/MachineryMaintenance.jsx"));
const OccupationWorkSupervision = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/OccupationWorkSupervision/OccupationWorkSupervision.jsx"));
const DynamicPileTesting = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/TestingInspectingAndExaminationOccupation/DynamicPileTesting/DynamicPileTesting.jsx"));
const LeakDetectionInwaterproofSystems = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/TestingInspectingAndExaminationOccupation/LeakDetectionInwaterproofSystems/LeakDetectionInwaterproofSystems.jsx"));
const DiplomaInSteelFixingOccupation = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInSteelFixingOccupation/DiplomaInSteelFixingOccupation.jsx"));
const FutureWorkplaceInConstructionEnvironment = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2Awards/FutureWorkplaceinConstructionEnvironment/FutureWorkplaceinConstructionEnvironment.jsx"));
const GreenAmbassadorWithinTheConstructionIndustry = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2Awards/GreenAmbassadorwithintheConstructionIndustry/GreenAmbassadorwithintheConstructionIndustry.jsx"));
const GreenAmbassadorWithinTheRetrofitSector = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2Awards/GreenAmbassadorwithintheRetrofitSector/GreenAmbassadorwithintheRetrofitSector.jsx"));
const RainscreenCladdingInstallation = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2Awards/RainscreenCladdingInstallation/RainscreenCladdingInstallation.jsx"));
const RecognisingDampMouldAndCondensationBuildings = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2Awards/RecognisingDampMouldAndCondensationBuildings/RecognisingDampMouldAndCondensationBuildings.jsx"));
const ConstructionPlantOperations = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/ConstructionPlantOperations /ConstructionPlantOperations.jsx"));
const ArticulatedDrawbarVehicle = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/Driving/ArticulatedDrawBarVehicle/ArticulatedDrawBarVehicle.jsx"));
const MotorCyclePedalCycle = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/Driving/MotorCyclePedalCycle/MotorCyclePedalCycle.jsx"));
const RigidVehicle = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/Driving/RigidVehicle/RigidVehicle.jsx"));
const Van = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/Driving/Van/Van.jsx"));
const Forklift = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/Forklift/Forklift.jsx"));
const HighwaysMaintenanceExcavation = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/HighwaysMaintenanceExcavation/HighwaysMaintenanceExcavation.jsx"));
const HighwaysMaintenanceKerbs = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/HighwaysMaintenanceKerbs/HighwaysMaintenanceKerbs.jsx"));
const DamageRepairAndResurfacing = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/AssociatedIndustrialService/DamageRepairAndResurfacing/DamageRepairAndResurfacing.jsx"));
const FacadePreservation = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/AssociatedIndustrialService/FacadePreservation/FaqsAndContactForm.jsx"));
const InstallingProtectiveComponents = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/AssociatedIndustrialService/InstallingProtectiveComponents/InstallingProtectiveComponents.jsx"));
const WaterJetting = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/AssociatedIndustrialService/WaterJetting/WaterJetting.jsx"));
const ColdFormedSteelFrame = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/FittedInteriors/FittedInteriors"));
const ErectionOfPrecastConcreteCladding = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/ConstructionUsing/ErectionOfPrecastConcreteCladding/ErectionOfPrecastConcreteCladding"));
const ErectionOfPrecastConcreteFlooring = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/ConstructionUsing/ErectionOfPrecastConcreteFlooring/ErectionOfPrecastConcreteFlooring"));
const FittedInteriors = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/FittedInteriors/FittedInteriors"));
const CavityBarrier = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/InteriorSystems/CavityBarrier/CavityBarrierInstallation.jsx"));
const CeilingFixing = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/InteriorSystems/CeilingFixing/CeilingFixing.jsx"));
const DryLiningFinishing = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/InteriorSystems/DryLining/DryLiningFinishing.jsx"));
const DryLiningBoarder = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/InteriorSystems/DryLiningBoarder/DryLiningBoarder.jsx"));
const NonHazardousWaste = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/NonHazardousWaste/NonHazardousWaste.jsx"));
const MovementGuide = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/MovementGuide/MovementGuide"));
const LoadingAndSecurity = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/LoadingAndSecurity/LoadingAndSecurity"));
const LayingAndDistributing = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/LayingAndDistributing/LayingAndDistributing"));
const ForkLiftTrucks = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/ForkLiftTrucks/ForkLiftTrucks"));
const Extracting = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/Extracting/Extracting"));
const Excavating = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQDiploma/PlantOperations/Excavating/Excavating"));
const CranesandSpecialistLifting = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/CranesandSpecialistLifting/CranesandSpecialistLifting"));
const Compacting = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/Compacting/Compacting"));
const Attachments = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/Attachments/Attachments"));
const OperationsGuide = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/OperationsGuide/OperationsGuide"));
const Processing = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/Processing/Processing"));
const RoadAndRail = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/RoadAndRail/RoadAndRail"));
const TransportingLoads = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/TransportingLoads/TransportingLoads"));
const SweepingCleaningClearing = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/SweepingCleaningClearing/SweepingCleaningClearing"));
const WorkPlatforms = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/WorkPlatforms/WorkPlatforms"));
const Excavation = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/Excavation/Excavation"));
const ExcavationAndReinstatement = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/ExcavationAndReinstatement/ExcavationAndReinstatement"));
const LayingkerbsAndChannels = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/LayingkerbsAndChannels/LayingkerbsAndChannels"));
const LocateAndExcavateUtilities = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/LocateAndExcavateUtilities/LocateAndExcavateUtilities"));
const MaintenanceResponseTeam = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/MaintenanceResponseTeam/MaintenanceResponseTeam"));
const ManualRoadBuildingFlexiblePavement = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/ManualRoadBuildingFlexiblePavement/ManualRoadBuildingFlexiblePavement"));
const ModularPavementConstruction = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/ModularPavementConstruction/ModularPavementConstruction"));
const Reinstatement = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/Reinstatement/Reinstatement"));
const RoadSweepingMachine = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/RoadSweepingMachine/RoadSweepingMachine"));
const StreetIronWork = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/StreetIronWork/StreetIronWork"));
const BlindsAndSolarShadingSystems = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/BlindsAndSolarShadingSystems/BlindsAndSolarShadingSystems"));
const IndustrialStorageSystemsInspection = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/IndustrialStorageSystemsInspection/IndustrialStorageSystemsInspection"));
const IndustrialStorageSystemsMaintenance = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/IndustrialStorageSystemsMaintenance/IndustrialStorageSystemsMaintenance"));
const JointSealantApplications = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/JointSealantApplications/JointSealantApplications"));
const LoadingBayEquipmentInstallation = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/LoadingBayEquipmentInstallation/LoadingBayEquipmentInstallation"));
const PointOfPurchase = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/PointOfPurchase/PointOfPurchase"));
const RoofLiningSystems = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/RoofLiningSystems/RoofLiningSystems"));
const WallAndFloorTiling = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/WallAndFloorTiling/WallAndFloorTiling"));
const GreenRoofInstaller = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/WaterProofMembrane/GreenRoofInstaller/GreenRoofInstaller"));
const GreenRoofSeeding = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/WaterProofMembrane/GreenRoofSeeding/GreenRoofSeeding"));
const GreenRoofSupport = lazy(() => import("./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/WaterProofMembrane/GreenRoofSupport/GreenRoofSupport.jsx"));
const NotFound = lazy(() => import("./components/index.jsx"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Home />} index={true} />
      <Route path="/nvq-level-1" element={<NvqProducts />} />
      <Route path="/nvq-level-2" element={<NvqProducts />} />
      <Route path="/nvq-level-3" element={<NvqProducts />} />
      <Route path="/nvq-level-4" element={<NvqProducts />} />
      <Route path="/nvq-level-5" element={<NvqProducts />} />
      <Route path="/nvq-level-6" element={<NvqProducts />} />
      <Route path="/nvq-level-7" element={<NvqProducts />} />
      <Route path="/nvq-level-4-demolition" element={<DemolitionLevel4 />} />
      <Route path="/nvq-level-4-retrofit" element={<RetrofitLevel4 />} />
      <Route
        path="/green-cscs-card-course-detail/:id"
        element={<AwardLevel1 />}
      />
      <Route path="/green-cscs-card-course" element={<LevelOneGreenCard />} />
      <Route
        path="/level-2-future-workplace-in-construction-environment"
        element={<FutureWorkplaceInConstructionEnvironment />}
      />
      <Route
        path="/level-2-green-ambassador-within-the-construction-industry"
        element={<GreenAmbassadorWithinTheConstructionIndustry />}
      />
      <Route
        path="/level-2-green-ambassador-within-the-retrofit-sector"
        element={<GreenAmbassadorWithinTheRetrofitSector />}
      />
      <Route
        path="/level-2-rainscreen-cladding-installation"
        element={<RainscreenCladdingInstallation />}
      />
      <Route
        path="/level-2-recognising-damp-mould-and-condensation-buildings"
        element={<RecognisingDampMouldAndCondensationBuildings />}
      />
      <Route
        path="/level-2-construction-plant-operations"
        element={<ConstructionPlantOperations />}
      />
      <Route
        path="/level-2-articulated-drawbar-vehicle"
        element={<ArticulatedDrawbarVehicle />}
      />
      <Route
        path="/level-2-motor-cycle-pedal-cycle"
        element={<MotorCyclePedalCycle />}
      />
      <Route path="/level-2-rigid-vehicle" element={<RigidVehicle />} />
      <Route path="/level-2-van" element={<Van />} />
      <Route path="/level-2-forklift" element={<Forklift />} />
      <Route
        path="/level-2-highways-maintenance-excavation"
        element={<HighwaysMaintenanceExcavation />}
      />
      <Route
        path="/level-2-highways-maintenance-kerbs"
        element={<HighwaysMaintenanceKerbs />}
      />
      <Route
        path="/level-2-damage-repair-and-resurfacing"
        element={<DamageRepairAndResurfacing />}
      />
      <Route
        path="/level-2-facade-preservation"
        element={<FacadePreservation />}
      />
      <Route
        path="/level-2-installing-protective-components"
        element={<InstallingProtectiveComponents />}
      />
      <Route path="/level-2-water-jetting" element={<WaterJetting />} />
      <Route
        path="/level-2-cold-formed-steel-frame-assembly"
        element={<ColdFormedSteelFrame />}
      />
      <Route
        path="/level-2-erection-precast-concrete-cladding"
        element={<ErectionOfPrecastConcreteCladding />}
      />
      <Route
        path="/level-2-erection-precast-concrete-flooring"
        element={<ErectionOfPrecastConcreteFlooring />}
      />
      <Route path="/level-2-fitted-interiors" element={<FittedInteriors />} />
      <Route path="/level-2-cavity-barrier" element={<CavityBarrier />} />{" "}
      <Route path="/level-2-ceiling-fixing" element={<CeilingFixing />} />{" "}
      <Route
        path="/level-2-dry-lining-finishing"
        element={<DryLiningFinishing />}
      />{" "}
      <Route
        path="/level-2-dry-lining-boarder"
        element={<DryLiningBoarder />}
      />
      <Route
        path="/level-2-non-hazardous-waste"
        element={<NonHazardousWaste />}
      />
      <Route path="/level-2-movement-guide" element={<MovementGuide />} />
      <Route
        path="/level-2-loading-and-security"
        element={<LoadingAndSecurity />}
      />
      <Route
        path="/level-2-laying-and-distributing"
        element={<LayingAndDistributing />}
      />
      <Route path="/level-2-fork-lift-trucks" element={<ForkLiftTrucks />} />
      <Route path="/level-2-extracting" element={<Extracting />} />
      <Route path="/level-2-excavating" element={<Excavating />} />
      <Route
        path="/level-2-cranes-and-specialist-lifting"
        element={<CranesandSpecialistLifting />}
      />
      <Route path="/level-2-compacting" element={<Compacting />} />
      <Route path="/level-2-attachments" element={<Attachments />} />
      <Route path="/level-2-operations-guide" element={<OperationsGuide />} />
      <Route path="/level-2-processing" element={<Processing />} />
      <Route path="/level-2-road-and-rail" element={<RoadAndRail />} />
      <Route
        path="/level-2-sweeping-cleaning-clearing"
        element={<SweepingCleaningClearing />}
      />
      <Route
        path="/level-2-transporting-loads"
        element={<TransportingLoads />}
      />
      <Route path="/level-2-work-platforms" element={<WorkPlatforms />} />
      <Route path="/level-2-excavation" element={<Excavation />} />
      <Route
        path="/level-2-excavation-and-reinstatement"
        element={<ExcavationAndReinstatement />}
      />
      <Route
        path="/level-2-laying-kerbs-and-channels"
        element={<LayingkerbsAndChannels />}
      />
      <Route
        path="/level-2-locate-and-excavate-utilities"
        element={<LocateAndExcavateUtilities />}
      />
      <Route
        path="/level-2-maintenance-response-team"
        element={<MaintenanceResponseTeam />}
      />
      <Route
        path="/level-2-manual-road-building-flexible-pavement"
        element={<ManualRoadBuildingFlexiblePavement />}
      />
      <Route
        path="/level-2-modular-pavement-construction"
        element={<ModularPavementConstruction />}
      />
      <Route path="/level-2-reinstatement" element={<Reinstatement />} />
      <Route
        path="/level-2-road-sweeping-machine"
        element={<RoadSweepingMachine />}
      />
      <Route path="/level-2-street-iron-work" element={<StreetIronWork />} />
      <Route
        path="/level-2-blinds-and-solar-shading-systems"
        element={<BlindsAndSolarShadingSystems />}
      />
      <Route
        path="/level-2-industrial-storage-systems-inspection"
        element={<IndustrialStorageSystemsInspection />}
      />
      <Route
        path="/level-2-industrial-storage-systems-maintenance"
        element={<IndustrialStorageSystemsMaintenance />}
      />
      <Route
        path="/level-2-joint-sealant-applications"
        element={<JointSealantApplications />}
      />
      <Route
        path="/level-2-loading-bay-equipment-installation"
        element={<LoadingBayEquipmentInstallation />}
      />
      <Route path="/level-2-point-of-purchase" element={<PointOfPurchase />} />,
      <Route
        path="/level-2-roof-lining-systems"
        element={<RoofLiningSystems />}
      />
      <Route
        path="/level-2-wall-and-floor-tiling"
        element={<WallAndFloorTiling />}
      />
      <Route
        path="/level-2-green-roof-installer"
        element={<GreenRoofInstaller />}
      />
      <Route
        path="/level-2-green-roof-seeding"
        element={<GreenRoofSeeding />}
      />
      <Route
        path="/level-2-green-roof-support"
        element={<GreenRoofSupport />}
      />
      <Route
        path="/nvq-level-4-traditional-And-heritage-building"
        element={<TraditionalandHeritageBuildingLevel4 />}
      />
      <Route
        path="/nvq-level-4-highways-maintenance-and-repair"
        element={<HighwaysMaintenanceandRepairLevel4 />}
      />
      <Route
        path="/nvq-level-4-residential-development"
        element={<ResidentiaDevelopmentLevel4 />}
      />
      <Route path="/nvq-level-4-awards" element={<AwardLevel4 />} />
      <Route path="/nvq-level-4-tunnelling" element={<TunnellingLevel4 />} />
      <Route
        path="/nvq-level-4-supervising-lifts"
        element={<SupervisingLiftsLevel4 />}
      />
      <Route
        path="/nvq-level-4-building-and-civil-engineering"
        element={<CivilandBuildingEngineeringLevel4 />}
      />
      <Route path="/sssts-online-course" element={<SsstsCourse />} />
      <Route
        path="/sssts-online-course-detail/:id"
        element={<SsstsCourseDetail />}
      />
      <Route
        path="/sssts-refresher-course"
        element={<SsstsRefresherCourse />}
      />
      <Route
        path="/sssts-refresher-course-detail/:id"
        element={<SsstsRefresherCourseDetail />}
      />
      <Route path="/smsts-online-course" element={<SmstsCourse />} />
      <Route
        path="/smsts-online-course-detail/:id"
        element={<SmstsCourseDetail />}
      />
      <Route
        path="/smsts-refresher-course"
        element={<SmstsRefresherCourse />}
      />
      <Route
        path="/smsts-refresher-course-detail/:id"
        element={<SmstsRefresherCourseDetail />}
      />
      <Route
        path="/nvq-level-7-construction-senior-management"
        element={<ConstructionSeniorManagement />}
      />
      {/* -construction-senior-management */}
      <Route path="/nvq-level-3-health&safety" element={<OhsLevel3 />} />
      <Route
        path="/nvq-level-6-health&safety-practice"
        element={<OhsLevel6 />}
      />
      <Route path="/business-solution" element={<HealthSafetyConsultancy />} />
      <Route
        path="/nvq-level-7-Strategic-Health-and-Safety-Leadership-and-Management"
        element={<OhsLevel7 />}
      />
      <Route
        path="/nvq-level-6-residential-dvelopment-pathway"
        element={<ResidentiaDevelopment />}
      />
      <Route
        path="/nvq-level-6-traditional-and-heritage-building"
        element={<TraditionalandHeritageBuilding />}
      />
      <Route
        path="/nvq-level-6-building-and--civil-engireering-Pathway"
        element={<CivilandBuildingEngineering />}
      />
      <Route
        path="/level-6-construction-in-site-management-demolition"
        element={<Demolition />}
      />
      <Route
        path="/level-6-construction-in-site-management-highways-maintenance-and-repair"
        element={<HighwaysMaintenanceandRepair />}
      />
      <Route
        path="/level-6-construction-in-site-management-senior-site-inspection"
        element={<SeniorSiteInspection />}
      />
      <Route
        path="/level-6-construction-in-site-management-contracting-operations-management"
        element={<ContractingOperationsManagement />}
      />
      <Route
        path="/level-3-award-in-preparing-for-the-future-workplace"
        element={<AwardLevel3 />}
      />
      <Route
        path="/level-3-pipework-components"
        element={<PipeworkComponents />}
      />
      <Route
        path="/level-3-moving-loads-construction"
        element={<MovingLoadsConstruction />}
      />
      <Route path="/level-3-plant-maintenance" element={<PlantMaintenance />} />
      <Route
        path="/level-3-shoring-and-pipe-laying"
        element={<ShoringAndPipeLaying />}
      />
      <Route path="/level-3-surveying" element={<Surveying />} />
      <Route
        path="/level-3-site-technical-support"
        element={<SiteTechnicalSupport />}
      />
      <Route
        path="/level-3-temporary-suspended-access"
        element={<TemporarySuspendedAccess />}
      />
      <Route path="/level-3-steeplejacking" element={<Steeplejacking />} />
      <Route
        path="/level-3-scaffolding-and-offshore"
        element={<ScaffoldingAndOffshore />}
      />
      <Route
        path="/level-3-lighting-protective-system"
        element={<LightingProtectiveSystem />}
      />
      <Route
        path="/level-3-lighting-protection-engineer"
        element={<LightingProtectionEngineer />}
      />
      <Route
        path="/level-3-rain-screen-wall-cladding-system"
        element={<RainScreenWallCladdingSystem />}
      />
      <Route
        path="/level-3-roof-and-wall-sheeting"
        element={<RoofAndWallSheeting />}
      />
      <Route path="/level-3-buying" element={<Buying />} />
      <Route path="/level-3-roof-slater" element={<RoofSlater />} />
      <Route path="/level-3-roof-tiler" element={<RoofTiler />} />
      <Route
        path="/level-3-roof-slater-tiler"
        element={<RoofSlaterAndTiler />}
      />
      <Route
        path="/level-3-design-coordinator"
        element={<DesignCoOrdinator />}
      />
      <Route path="/level-3-estimating" element={<Estimating />} />
      <Route path="/level-3-general" element={<General />} />
      <Route path="/level-3-planning" element={<Planning />} />
      <Route
        path="/level-3-welding-construction"
        element={<WeldingConstruction />}
      />
      <Route
        path="/level-3-decorative-finishing"
        element={<DecorativeFinishing />}
      />
      <Route
        path="/level-3-demolition-chargehand"
        element={<DemolitionChargehand />}
      />
      <Route
        path="/level-3-demolition-operative"
        element={<DemolitionOperative />}
      />
      <Route path="/level-3-demolition-plant" element={<DemolitionPlant />} />
      <Route
        path="/level-3-external-wall-insulation-boarder"
        element={<ExternalWallInsulationBoarder />}
      />
      <Route path="/level-3-room-in-roof" element={<RoomInRoof />} />
      <Route path="/level-3-park-homes" element={<ParkHomes />} />
      <Route
        path="/level-3-internal-insulation-walls"
        element={<InternalInsulationWalls />}
      />
      <Route
        path="/level-3-insulating-framed-sections"
        element={<InsulatingFramedSections />}
      />
      <Route path="/level-3-hybrid-wall" element={<HybridWall />} />
      <Route
        path="/level-3-external-wall-insulation-finisher"
        element={<ExternalWallInsulationFinisher />}
      />
      <Route
        path="/level-3-external-wall-insulation-boarder-and-finisher"
        element={<ExternalWallInsulationBoarderAndFinisher />}
      />
      <Route
        path="/level-3-complex-suspended-ceiling-systems"
        element={<ComplexSuspendedCeilingSystems />}
      />
      <Route
        path="/level-3-service-operable-partition-walls"
        element={<ServiceOperablePartitionWalls />}
      />
      <Route
        path="/level-3-stretched-ceiling-fixing"
        element={<StretchedCeilingFixing />}
      />
      <Route path="/level-3-plastering-solid" element={<PlasteringSolid />} />
      <Route
        path="/level-3-plastering-fibrous"
        element={<PlasteringFibrous />}
      />
      <Route
        path="/level-3-diploma-in-plant-installations-tower-crane"
        element={<DiplomaInPlantInstallationsTowerCrane />}
      />
      <Route
        path="/level-3-diploma-in-plant-installations-hoist"
        element={<DiplomaInPlantInstallationsHoist />}
      />
      <Route
        path="/level-3-diploma-in-wall-and-floor-tiling"
        element={<DiplomaInWallAndFloorTiling />}
      />
      <Route
        path="/level-3-diploma-in-trowel-occupation"
        element={<DiplomaInTrowelOccupation />}
      />
      <Route
        path="/level-3-diploma-in-stonemasonry-memorial-masonry"
        element={<DiplomaInStonemasonryMemorialMasonry />}
      />
      <Route
        path="/level-3-diploma-in-stonemasonry-banker-masonry"
        element={<DiplomaInStonemasonryBankerMasonry />}
      />
      <Route
        path="/level-3-diploma-in-steel-fixing-occupation"
        element={<DiplomaInSteelFixingOccupation />}
      />
      <Route
        path="/level-3-demolition-chargehand"
        element={<DemolitionChargehand />}
      />
      <Route
        path="/level-3-demolition-operative"
        element={<DemolitionOperative />}
      />
      <Route path="/level-3-demolition-plant" element={<DemolitionPlant />} />
      <Route
        path="/level-3-decorative-finishing"
        element={<DecorativeFinishing />}
      />
      <Route
        path="/level-3-architectural-joinery"
        element={<ArchitecturalJoinery />}
      />
      <Route
        path="/level-3-shoplifitting-bench-work"
        element={<ShoplifittingBenchWork />}
      />
      <Route
        path="/level-3-shoplifitting-site-work"
        element={<ShoplifittingSiteWork />}
      />
      <Route path="/level-3-site-carpentry" element={<SiteCarpentry />} />
      <Route
        path="/level-3-structure-post-and-beam"
        element={<StructurePostAndBeam />}
      />
      <Route
        path="/level-3-timber-frame-erection"
        element={<TimberFrameErection />}
      />
      <Route path="/level-3-wheel-wrighting" element={<WheelWrighting />} />
      <Route path="/level-3-formwork" element={<Formwork />} />
      <Route
        path="/level-3-heritage-skills-fully-supported-lead-and-hard-metal-roofing"
        element={<HeritageSkillsFullySupportedLeadAndHardMetalRoofing />}
      />
      <Route
        path="/level-3-heritage-skills-mason"
        element={<HeritageSkillsMason />}
      />
      <Route
        path="/level-3-heritage-skills-roof-slatting-and-tiling-occupations"
        element={<HeritageSkillsRoofSlattingAndTilingOccupations />}
      />
      <Route
        path="/level-3-heritage-skills-wood-occupations"
        element={<HeritageSkillsWoodOccupations />}
      />
      <Route
        path="/level-3-machinery-maintenance"
        element={<MachineryMaintenance />}
      />
      <Route
        path="/level-3-occupation-work-supervision"
        element={<OccupationWorkSupervision />}
      />
      <Route
        path="/level-3-dynamic-pile-testing"
        element={<DynamicPileTesting />}
      />
      <Route
        path="/level-3-leak-detection-inwaterproof-systems"
        element={<LeakDetectionInwaterproofSystems />}
      />
      <Route
        path="/level-5-award-in-preparing-for-the-future-workplace"
        element={<AwardLevel5 />}
      />
      <Route
        path="/level-5-nvq-diploma-in-controlling-lifting-planning-lifts"
        element={<PlanningLifts />}
      />
      <Route path="/nvq-level-6-tunneling" element={<Tunnelling />} />{" "}
      <Route path="/nvq-level-6-retrofit" element={<Retrofit />} />{" "}
      <Route path="/citb-courses" element={<CitbCourses />} />
      <Route path="/cscs-cards" element={<CSCSCardPage />} />
      <Route path="/cart-checkout" element={<CartScreen />} />
      <Route path="/user-checkout" element={<CustomerDetails />} />
      <Route path="/stripe-checkout" element={<CheckoutForm />} />
      <Route path="/return" element={<Return />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/cookies-policy" element={<CookiesPolicyPage />} />
      <Route path="/term-and-conditions" element={<TermsAndConditionsPage />} />
    </Route>
  )
);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
if (process.env.NODE_ENV !== "development") {
}

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          <CartProvider>
            <RouterProvider router={router} />
          </CartProvider>
        </QueryClientProvider>
      </ToastProvider>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
