import React, { useState, useEffect } from "react";
import axios from "axios";
import citbLogo from "../../../assets/CitbPage/citb_logo.png";
import { useCart } from "../../../CartContext";

const SsstsCourse = () => {
  const [activeFilter, setActiveFilter] = useState("dayRelease");
  const [activeMonth, setActiveMonth] = useState(0);
  const { addToCart } = useCart();
  const toggleMonth = (month) => {
    setActiveMonth(activeMonth === month ? null : month);
  };
  const [data, setData] = useState([]);

  function formatDateRange(startDate, endDate) {
    // Convert the startDate and endDate into Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Define an array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const startDay = start.getDate().toString().padStart(2, "0");
    const startMonth = monthNames[start.getMonth()];

    const endDay = end.getDate().toString().padStart(2, "0");
    const endMonth = monthNames[end.getMonth()];

    const timeDifference = end - start;
    const numberOfDays = Math.ceil(timeDifference / (1000 * 3600 * 24)) + 1;

    const dateRange = `From ${startDay}-${startMonth} to ${endDay}-${endMonth}`;

    return { dateRange, days: `${numberOfDays} Days` };
  }

  useEffect(() => {
    axios
      .get(`slots/filter`, {
        params: {
          filter: "scheduleDays",
          equal: activeFilter,
          productId: "66028dcab9e94f407d047293",
        },
      })
      .then((result) => {
        setData(result);
        console.log(result);
      })
      .catch((err) => console.log(err));
  }, [activeFilter]);
  const usedFilter = ["dayRelease", "weekend"];
  const filters = ["Day Release", "Weekend"];
  const formatYearMonth = (year, month) => {
    const date = new Date(year, month - 1);
    const monthName = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return `${monthName[date.getMonth()]} ${year}`;
  };

  const handleAddToCart = (product) => {
    product.isEmi = false;
    addToCart(product);
  };

  return (
    <div className="bg-white min-h-screen flex flex-col items-center py-10">
      <div className="w-11/12  mt-[100px] md:w-9/12 lg:w-8/12 xl:w-7/12">
        {/* White Box */}
        <div className=" p-6">
          <div className="flex justify-center mb-4">
            <img src={citbLogo} alt="CITB Logo" className="h-16 w-auto" />
          </div>
          <h1 className="text-2xl sm:text-3xl md:text-4xl text-center font-bold text-gray-900">
            SSSTS Courses
          </h1>
          <p className="text-[#FF3131] text-center mt-2">Available Courses</p>

          {/* Filter Buttons */}
          <div className="flex flex-col justify-center sm:flex-row gap-4 mt-4">
            {filters.map((filter, index) => (
              <button
                key={filter}
                onClick={() => setActiveFilter(usedFilter[index])}
                className={`w-full sm:w-auto px-4 py-2 rounded font-semibold transition ${
                  activeFilter === usedFilter[index]
                    ? "bg-[#FF3131] text-white shadow-lg"
                    : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                }`}>
                SSSTS {filter}
              </button>
            ))}
          </div>
        </div>

        {/* Dark Box */}
        <div className=" mt-4">
          {/* Monthly Dropdowns */}
          {data?.data?.result.map((slots, index) => (
            <div key={index} className="mb-4">
              <div
                className="flex justify-between items-center bg-white p-4 border-b-2 cursor-pointer"
                onClick={() => toggleMonth(index)}>
                <h2 className="text-[#FF3131] font-bold">
                  {formatYearMonth(slots["_id"].year, slots["_id"].month)}
                </h2>
                <span className="text-black">
                  {activeMonth === index ? "-" : "+"}
                </span>
              </div>

              {activeMonth === index && (
                <div className="bg-white p-4">
                  {/* Course Boxes */}
                  {slots?.slots.map((slot, index) => (
                    <div
                      onClick={() => {
                        window.location.href = `/sssts-online-course-detail/${slot._id}`;
                      }}
                      key={index}
                      className="flex flex-col md:flex-row justify-between items-center bg-white hover:bg-gray-100 shadow-md rounded-b-xl border-l-4 border-[#FF3131] my-4 p-4 hover:shadow-md hover:shadow-[#FF3131] transition duration-300">
                      {/* Left Section: Course Info */}
                      <div className="flex items-center w-full md:w-2/3 space-x-4">
                        {/* Placeholder for Icon or Logo */}
                        <div className="w-12 h-12 bg-[#FF3131] text-white flex items-center justify-center rounded-full font-bold">
                          {index + 1}
                        </div>

                        {/* Course Details */}
                        <div className="text-left">
                          <p className="text-gray-800 font-semibold text-lg">
                            SSSTS Course - 2 Day{" "}
                            <span className="text-blue-500 text-sm">
                              (Online)
                            </span>
                          </p>
                          <div className="flex flex-col mt-1 text-sm text-gray-600">
                            <span>
                              📅{" "}
                              {
                                formatDateRange(slot.startDate, slot.endDate)
                                  .dateRange
                              }
                            </span>
                            <span>⏳ {slot?.scheduleDates?.length} days</span>
                          </div>
                          <p className="text-[#FF3131] font-bold text-md mt-2">
                            £{slot?.productDetails?.price} + VAT
                          </p>
                        </div>
                      </div>

                      {/* Right Section: Buttons */}
                      <div className="flex gap-2 w-full mt-4 md:mt-0 justify-center md:justify-end">
                        <button
                          className="bg-[#FF3131] text-white px-4 py-2 rounded hover:bg-red-600 transition"
                          onClick={() => handleAddToCart(slot)}>
                          Add to Cart
                        </button>
                        <a href={`/sssts-online-course-detail/${slot._id}`}>
                          <button className="bg-gray-200 px-4 py-2 rounded hover:bg-gray-300 transition">
                            More Info
                          </button>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SsstsCourse;
