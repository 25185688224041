import React, { useEffect, useState } from "react";
import { Flex, Box } from "@chakra-ui/react";
// import TopBar from "./components/topBar";
import { json, Outlet, useLocation } from "react-router-dom/dist";
import TawkToScript from "./TawkToScript";
import axios from "axios";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import { useCart } from "./CartContext";
import Popup from "./components/popUp/PopUp";
import { Helmet } from "react-helmet";

function App() {
  const location = useLocation();
  const { showCart } = useCart();
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleCart = async () => {
      const storedCart = JSON.parse(localStorage.getItem("cart"));

      try {
        if (
          storedCart &&
          storedCart.hasOwnProperty("_id") &&
          storedCart.hasOwnProperty("isEmi")
        ) {
          // Fetch existing cart
          const response = await axios.get(`cart/read/${storedCart._id}`);
          const fetchedCart = response?.data;
          if (
            fetchedCart &&
            fetchedCart.success &&
            fetchedCart.hasOwnProperty("_id")
          ) {
            localStorage.setItem("cart", JSON.stringify(fetchedCart));
          }
        } else {
          // Remove invalid or missing cart from localStorage
          localStorage.removeItem("cart");
          await createCart();
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data.success === false
        ) {
          // Remove invalid cart from localStorage in case of 404 error
          localStorage.removeItem("cart");
          await createCart();
        } else {
          console.error("Error fetching cart:", error);
        }
      }
    };

    const createCart = async () => {
      try {
        const response = await axios.post(`cart/create`);
        const createdCart = response?.data?.result;
        createdCart.isEmi = true;
        if (createdCart && createdCart.hasOwnProperty("_id")) {
          localStorage.setItem("cart", JSON.stringify(createdCart));
        }
      } catch (error) {
        console.error("Failed to create cart:", error);
      }
    };

    handleCart();
  }, []); // Add an empty dependency array to run only once
  // useEffect(() => {
  //   showCart();
  // }, []);
  // const { isPending, error, data } = useQuery({
  //   queryKey: ["productCategory"],
  //   queryFn: () => axios.get(`productCategory/get-all`),
  // });
  // useEffect(() => {
  //   if (data && data.data && data.data.result) {
  //     const categoryData = {};

  //     data.data.result.forEach((category) => {
  //       const { _id, title } = category;
  //       categoryData[title] = _id;
  //     });

  //     localStorage.setItem("category", JSON.stringify(categoryData));
  //   }
  // }, [data]);

  return (

    <div>
      {location.pathname === "/user-info" ? (
        <></>
      ) : (
        <div>
          <Flex
            minH={"100vh"}
            flexDirection={"column"}
            fontFamily={"Poppins, sans-serif"}
          >
            <TawkToScript />
            {/* <Clara /> */}
            <Box>
              <Navbar />
            </Box>
            <Box flex="1">
              <Box minH={"calc(100vh- 100px)"}>
                <main>
                  <Outlet />
                </main>
              </Box>
            </Box>
            {/* <Popup show={showPopup} onClose={() => setShowPopup(false)} /> */}
            <Box as="footer">
              <Footer />
            </Box>
          </Flex>
        </div>
      )}

    </div>
  );
}

export default App;
