import React from "react";
import backgroundImage from "../../assets/contactPage/contactUs.png";
import contactUs from "../../assets/contactPage/image 38.svg";
import { LuPhoneCall } from "react-icons/lu";
import { MdOutlineAttachEmail } from "react-icons/md";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import axios from "axios";
import { useMutation } from "react-query";
import { useForm, ValidationError } from "@formspree/react";
import { useToast } from "../../components/Toast/toast";

const ContactPage = () => {
  const useCreatePerson = async ({
    firstname,
    lastname,
    email,
    phone,
    message,
  }) => {
    const response = await axios.post("people/create", {
      firstname,
      lastname,
      email,
      phone,
      message,
    });
    return response.data;
  };

  const [state, handleSubmit] = useForm("xeqypwnz");
  const { isLoading, isError, error, data, mutate } =
    useMutation(useCreatePerson);
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const firstname = e.target.elements.firstname.value;
    const lastname = e.target.elements.lastname.value;
    const email = e.target.elements.email.value;
    const phone = e.target.elements.phone.value;
    const message = e.target.elements.message.value;

    try {
      await mutate({ firstname, lastname, email, phone, message });
    } catch (error) {
      console.error(error);
    }
  };

  const { addToast } = useToast();
  const handleCombinedSubmit = async (e) => {
    await handleSubmit(e); // Trigger useForm's handleSubmit
    addToast(
      "We have received your query, please rest assured our team will get back to you",
      "success"
    );
    await handleSubmitForm(e);
    e.target.reset();
  };

  return (
    <div className="flex flex-col items-center">
      {/* Outer Box */}
      <div className="w-full max-w-5xl bg-white rounded-lg shadow-lg overflow-hidden">
        {/* Upper Box */}
        <div
          className="relative h-96 md:h-96 bg-cover bg-center"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}>
          <div className="absolute inset-0 bg-[#0C1524] bg-opacity-90 flex items-center justify-center">
            <h1 className="text-white text-2xl md:text-4xl mt-4 font-bold text-center">
              Can’t Find What You Are Looking For?
            </h1>
          </div>
        </div>

        {/* Lower Box */}
        <div className=" relative grid grid-cols-1 md:grid-cols-2 p-6 md:p-12 z-10 -mt-[100px]">
          {/* Left Inner Box */}
          <div className="bg-[#0C1524]  text-white shadow-inner shadow-slate-500 sm:rounded-l-xl p-12 flex flex-col items-center space-y-6">
            <img
              src={contactUs}
              alt="Contact Us"
              className="w-52 h-52 object-contain"
            />
            {/* Contact Info */}
            <div className="flex text-sm flex-col items-start space-y-4 w-2/3">
              {/* Phone */}
              <div className="flex items-center space-x-4">
                <LuPhoneCall className="w-6 h-6" />
                <a href="tel:02080599944" className="hover:underline">
                  02080-599944
                </a>
              </div>
              {/* Email */}
              <div className="flex items-center space-x-4">
                <MdOutlineAttachEmail className="w-6 h-6" />
                <a href="mailto:sales@m2hse.co.uk" className="hover:underline">
                  sales@m2hse.co.uk
                </a>
              </div>
              {/* Address */}
              <div className="flex items-start space-x-4">
                <HiOutlineBuildingOffice2 className="w-6 h-6" />
                <p>
                  M2HSE Training Ltd,
                  <br />
                  Ceme Business Campus, Commercial 1,
                  <br />
                  Marsh Way, Rainham, RM13 8EU
                </p>
              </div>
            </div>
          </div>

          {/* Right Inner Box */}
          <div className="bg-gray-50 sm:rounded-r-xl p-12 shadow-md">
            <h2 className="text-lg font-semibold text-gray-700 mb-8">
              Enter Details
            </h2>
            <form onSubmit={handleCombinedSubmit} className="space-y-4">
              {/* First Name */}
              <div>
                <label
                  htmlFor="firstname"
                  className="block text-sm font-medium text-gray-700">
                  First Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  required
                  className="mt-1 block w-full border-gray-300 rounded-xl shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-10"
                />
              </div>

              {/* Last Name */}
              <div>
                <label
                  htmlFor="lastname"
                  className="block text-sm font-medium text-gray-700">
                  Last Name<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  required
                  className="mt-1 block w-full border-gray-300 rounded-xl shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-10"
                />
              </div>

              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  Email<span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="mt-1 block w-full border-gray-300 rounded-xl shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-10"
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>

              {/* Phone Number */}
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700">
                  Phone Number<span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  required
                  className="mt-1 block w-full border-gray-300 rounded-xl shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-10"
                />
              </div>

              {/* Message */}
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  className="mt-1 block w-full border-gray-300 rounded-xl shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              {/* Submit Button */}
              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={state.submitting}
                  className="px-6 py-2 bg-green-600 text-white font-medium rounded-xl shadow hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
