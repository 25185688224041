import React from "react";
import Icon from "../../assets/logo.png";

const Footer = () => {
  return (
    <div className="text-[#313232] w-full relative px-10 py-10 bg-white md:py-12 md:px-10 lg:px-10 h-auto">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-stretch h-full space-y-8 md:space-y-0">
        {/* Left Section with Logo and Info */}
        <div className="flex flex-col space-y-4 md:w-1/3 lg:w-1/4">
          <div className="mb-2">
            <img src={Icon} alt="Icon" className="w-[172px] h-[48px] ml-4" />
          </div>
        </div>

        {/* Right Section with Links and Contact */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full md:w-2/3 lg:w-3/4">
          {/* Support */}
          <div className="font-poppins font-bold text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
            <h3 className="mb-4 text-[18px]">Support</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="/term-and-conditions"
                  className="hover:underline font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                  Terms of Service
                </a>
              </li>
              <li>
                <a
                  href="/privacy-policy"
                  className="hover:underline font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="/cookies-policy"
                  className="hover:underline font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                  Cookies Policy
                </a>
              </li>
              <li>
                <a
                  href="/cookies-policy"
                  className="hover:underline font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                  Refund Policy
                </a>
              </li>
            </ul>
          </div>

          {/* Company */}
          <div className="font-poppins font-bold text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
            <h3 className="mb-4 text-[18px]">Company</h3>
            <ul className="space-y-2 text-[16px]">
              <li>
                <a
                  href="/about-us"
                  className="hover:underline font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/nvq-level-6"
                  className="hover:underline font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                  NVQ Courses
                </a>
              </li>
              <li>
                <a
                  href="/citb-courses"
                  className="hover:underline font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                  CITB Courses
                </a>
              </li>
              {/* <li>
                <a
                  href="/cscs-cards"
                  className="hover:underline font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                  CSCS Cards
                </a>
              </li>
              <li>
                <a
                  href="/plant-operation"
                  className="hover:underline font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                  Plant Operations
                </a>
              </li> */}
            </ul>
          </div>

          {/* Contact Us */}
          <div className="font-poppins font-bold text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
            <h3 className="mb-4 text-[18px]">Contact Us</h3>
            <ul className="space-y-2">
              <li className="font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                Email:{" "}
                <a href="mailto:sales@m2hse.co.uk" className="hover:underline">
                  sales@m2hse.co.uk
                </a>
              </li>
              <li className="font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                Phone:{" "}
                <a href="tel:02080599944" className="hover:underline">
                  02080-599944
                </a>
              </li>
            </ul>
          </div>

          {/* Follow Us */}
          <div className="font-poppins font-bold text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
            <h3 className="mb-4 text-[18px]">Follow Us</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=100090575422097"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/m2hse-training/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/m2hsetraining/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline font-medium text-[12px] leading-[18px] sm:text-[14px] sm:leading-[22px] md:text-[16px] md:leading-[24px]">
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Bottom Section for Address, Copyright, and Company Info */}
      <div className="border-t border-gray-300 mt-8 pt-4 text-center font-poppins font-bold text-[14px] leading-[20px] md:text-[17px] md:leading-[23.63px]">
        <p className="mb-2">
          Address: M2HSE Training Ltd. Ceme Business Campus, Commercial 1, Marsh
          Way, Rainham, RM13 8EU
        </p>
        <p className="mb-2">COPYRIGHT © 2024 M2HSE</p>
        <p>Company Registration No: 12804208 | VAT Number: 357511104</p>
      </div>
    </div>
  );
};

export default Footer;
