import { useEffect, useState } from "react";
import jsonData from "../../assets/data/product.json";
import axios from "axios";
import { useCart } from "../../CartContext";
import { ToastContainer, toast } from "react-toastify";

import { motion } from "framer-motion";

import klarna from "../../assets/klarna_logo/502240a8-506e-44b3-84eb-d8e57609ab93_Marketing_Badge_With_Clear_Space.png";

const CardsScreen = () => {
  const [data, setData] = useState([]);
  const fullUrl = window.location.href;
  const lastPathSegment = fullUrl.split("/").pop();
  const displayData = jsonData[lastPathSegment];
  const { addToCart } = useCart();

  useEffect(() => {
    axios
      .get(`product/filter`, {
        params: {
          filter: "productCategory",
          equal: `${displayData.data}`,
        },
      })
      .then((result) => {
        setData(result);
        console.log(result);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleAddToCart = (product) => {
    addToCart(product);

    toast.success("Added to cart!");
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <div className="relative">
      <div className="absolute inset-0 bg-gradient-to-r from-[#0C1524] to-[#17315E] bg-400% animate-gradient-move z-0"></div>

      {/* Content */}
      <div className="relative z-10 px-4 sm:px-6 lg:px-20 py-4">
        {/* Top Section */}
        <div className="text-center mt-[150px] mb-10 z-10">
          <h1 className="text-5xl md:text-5xl mb-[15px] font-bold text-[#FF3131]">
            {displayData?.heading}
          </h1>
          <div
            className="trustpilot-widget my-4 px-4 sm:px-8 md:px-16 lg:px-24"
            data-locale="en-GB"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="65e09121938229973209b5ac"
            data-style-height="120px"
            data-style-width="100%"
            data-theme="dark">
            <a
              href="https://uk.trustpilot.com/review/m2hse.co.uk"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white">
              Trustpilot
            </a>
          </div>
          <p className="text-gray-100 text-2xl mb-4">Available Courses</p>

          <a href="/contact-us">
            <button className="px-4 py-2 bg-[#FF3131] text-white font-bold rounded-md shadow-md hover:bg-red-700 transition">
              Enquire Now
            </button>
          </a>
        </div>

        {/* Course Cards Section */}
        <motion.div
          className="p-10 rounded-lg z-10 relative mb-28"
          initial="hidden"
          animate="visible"
          variants={containerVariants}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {data?.data?.result?.map((products, index) => (
              <motion.div
                key={index}
                className="p-6 rounded-xl shadow-inner shadow-white bg-gradient-to-br from-gray-800 to-gray-900 hover:shadow-lg hover:scale-105 transition-transform duration-300"
                variants={cardVariants}>
                {/* Wrap the entire card in an anchor tag */}
                <a href={products.detailsPage} className="block w-full h-full">
                  <h3 className="text-sm text-gray-400 font-semibold mb-1">
                    {displayData.heading}
                  </h3>
                  <p className="text-lg text-white font-bold">
                    {products.name}
                  </p>
                  <div className="flex items-center text-center">
                    <img src={klarna} alt="Klarna_Logo" className="w-[80px]" />
                    <p className="text-blue-400 font-normal">
                      available at checkout
                    </p>
                  </div>
                  <ul className="list-disc pl-4 text-gray-400 text-sm space-y-1 mb-4">
                    {products.points.map((point, idx) => (
                      <li key={idx}>{point}</li>
                    ))}
                  </ul>
                  <p className="text-blue-400 hover:text-blue-500 text-sm">
                    Course Details
                  </p>
                  <div className="flex justify-between items-end mt-4">
                    <p className="text-gray-200 font-bold text-lg">
                      £{products.price} + VAT
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleAddToCart(products);
                      }}
                      className="px-4 py-2 border-2 border-blue-500 text-white font-semibold rounded-xl shadow-md hover:bg-blue-700 transition">
                      Book Now
                    </button>
                  </div>
                </a>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default CardsScreen;
