import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./customTheme";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom/dist";
import { QueryClient, QueryClientProvider } from "react-query";
import Home from "./screens/Home";
import CartScreen from "./screens/Cart_Screen";
import { API_BASE_URL } from "./config/serverApiConfig";
import axios from "axios";
import CitbCourses from "./components/CITB_Courses/CitbCardsScreen";
import NvqProducts from "./components/Nvq_Product_page/index.jsx";
import AboutUs from "./screens/About_Us/index.jsx";
import CSCSCardPage from "./screens/CscsCards_screen/index.jsx";
import PrivacyPolicy from "./screens/Privacy_Policy/index.jsx";
import TermsAndConditionsPage from "./screens/Terms_And_Condition_Screen/index.jsx";
import CookiesPolicyPage from "./screens/Cookies_Policy/index.jsx";
import ContactPage from "./screens/Contact_Us/index.jsx";
import SsstsCourse from "./screens/CITB_Courses/SsstsCourses/Sssts_Course.jsx";
import SsstsCourseDetail from "./screens/CITB_Courses/SsstsCourses/SsstsDetails.jsx";
import SsstsRefresherCourse from "./screens/CITB_Courses/SsstsRefresher/SsstsRefresherCourse.jsx";
import SmstsRefresherCourseDetail from "./screens/CITB_Courses/SmstsRefresher/SmstsRefresherDetails.jsx";
import SmstsCourse from "./screens/CITB_Courses/SmstsCourses/Smsts_Course.jsx";
import SmstsCourseDetail from "./screens/CITB_Courses/SmstsCourses/SmstsDetails.jsx";
import SmstsRefresherCourse from "./screens/CITB_Courses/SmstsRefresher/SmstsRefresherCourse.jsx";
import CustomerDetails from "./screens/Cart_Screen/CustomerDetails.jsx";
import { CartProvider } from "./CartContext.js";
import { ToastProvider } from "./components/Toast/toast.jsx";
import ConstructionSeniorManagement from "./screens/NVQ_Courses/NVQ_Level_7/ConstructionSeniorManagement.jsx";
import OhsLevel3 from "./screens/NVQ_Courses/NVQ_Health_and_Safety/OHS_Level3/OhsLevel3.jsx";
import OhsLevel6 from "./screens/NVQ_Courses/NVQ_Health_and_Safety/OHS_Level6/OhsLevel6.jsx";
import OhsLevel7 from "./screens/NVQ_Courses/NVQ_Health_and_Safety/OHS_Level7/OhsLevel7.jsx";
import ResidentiaDevelopment from "./screens/NVQ_Courses/NVQ_Level_6/ResidentialDevelopment/ResidentialDevelopment .jsx";
import HealthSafetyConsultancy from "./screens/Business_Solutions/BusinessSolutions.jsx";
import TraditionalandHeritageBuilding from "./screens/NVQ_Courses/NVQ_Level_6/TraditionalandHeritageBuilding/TraditionalandHeritageBuilding.jsx";
import Tunnelling from "./screens/NVQ_Courses/NVQ_Level_6/Tunnelling/Tunnelling.jsx";
import Retrofit from "./screens/NVQ_Courses/NVQ_Level_6/Retrofit/Retrofit.jsx";
import ContractingOperationsManagement from "./screens/NVQ_Courses/NVQ_Level_6/ContractingOperationsManagement/ContractingOperationsManagement.jsx";
import CivilandBuildingEngineering from "./screens/NVQ_Courses/NVQ_Level_6/CivilandBuildingEngineering/CivilandBuildingEngineering.jsx";
import Demolition from "./screens/NVQ_Courses/NVQ_Level_6/Demolition/Demolition.jsx";
import SeniorSiteInspection from "./screens/NVQ_Courses/NVQ_Level_6/SeniorSiteInspection/SeniorSiteInspection.jsx";
import AwardLevel5 from "./screens/NVQ_Courses/NVQ_Level_5/AwardLevel5/AwardLevel5.jsx";
import PlanningLifts from "./screens/NVQ_Courses/NVQ_Level_5/PlanningLifts/PlanningLifts.jsx";
import AwardLevel3 from "./screens/NVQ_Courses/NVQ_Level_3/Level3_Awards/Level3Award.jsx";
import SsstsRefresherCourseDetail from "./screens/CITB_Courses/SsstsRefresher/SsstsRefresherDetails.jsx";
import CheckoutForm from "./screens/Cart_Screen/checkout.jsx";
import Return from "./screens/Cart_Screen/return.jsx";
import RefundPolicy from "./screens/RefundPolicy/RefundPolicy.jsx";
import HighwaysMaintenanceandRepair from "./screens/NVQ_Courses/NVQ_Level_6/HighwaysMaintenanceandRepair/HighwaysMaintenanceandRepair.jsx";
import DemolitionLevel4 from "./screens/NVQ_Courses/NVQ_Level_4/Demolition/Demolition.jsx";
import RetrofitLevel4 from "./screens/NVQ_Courses/NVQ_Level_4/Retrofit/Retrofit.jsx";
import TraditionalandHeritageBuildingLevel4 from "./screens/NVQ_Courses/NVQ_Level_4/TraditionalandHeritageBuilding/TraditionalandHeritageBuilding.jsx";
import ResidentiaDevelopmentLevel4 from "./screens/NVQ_Courses/NVQ_Level_4/ResidentialDevelopment/ResidentialDevelopment.jsx";
import HighwaysMaintenanceandRepairLevel4 from "./screens/NVQ_Courses/NVQ_Level_4/HighwaysMaintenanceandRepair/HighwaysMaintenanceandRepair.jsx";
import CivilandBuildingEngineeringLevel4 from "./screens/NVQ_Courses/NVQ_Level_4/CivilandBuildingEngineering/CivilandBuildingEngineering.jsx";
import SupervisingLiftsLevel4 from "./screens/NVQ_Courses/NVQ_Level_4/SupervisingLifts/SupervisingLifts.jsx";
import AwardLevel4 from "./screens/NVQ_Courses/NVQ_Level_4/AwardLevel4/AwardLevel4.jsx";
import TunnellingLevel4 from "./screens/NVQ_Courses/NVQ_Level_4/Tunnelling/Tunnelling.jsx";
import PipeworkComponents from "./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/InstallationOfPipeworkComponentsConstruction/InstallationOfPipeworkComponentsConstruction.jsx";
import MovingLoadsConstruction from "./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/MovingLoadsConstrutions/MovingLoadsConstruction.jsx";
import PlantMaintenance from "./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/PlantMaintenanceConstrution/PlantMaintenance.jsx";
import ShoringAndPipeLaying from "./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/ShoringAndPipeLaying/ShoringAndPipeLaying.jsx";
import WeldingConstruction from "./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/WeldingConstruction/WeldingConstruction.jsx";
import Surveying from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/ConstructionContractingOperations/Surveying/Surveying.jsx";
import SiteTechnicalSupport from "./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/WeldingConstruction/WeldingConstruction.jsx";
import TemporarySuspendedAccess from "./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/WeldingConstruction/WeldingConstruction.jsx";
import Steeplejacking from "./screens/NVQ_Courses/NVQ_Level_3/Level3_Diploma/WeldingConstruction/WeldingConstruction.jsx";
import ScaffoldingAndOffshore from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/AccessingOperationAndRigging/ScaffoldingAndOffshore/ScaffoldingAndOffshore.jsx";
import LightingProtectiveSystem from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/AccessingOperationAndRigging/LightingProtectiveSystem/LightingProtectiveSystem.jsx";
import LightingProtectionEngineer from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/AccessingOperationAndRigging/LightingProtectionEngineer/LightingProtectionEngineer.jsx";
import RainScreenWallCladdingSystem from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/CladdingOperations/RainScreenWallCladdingSystem/RainScreenWallCladdingSystem.jsx";
import RoofAndWallSheeting from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/CladdingOperations/RoofAndWallSheeting/RoofAndWallSheeting.jsx";
import Buying from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/ConstructionContractingOperations/Buying/Buying.jsx";
import DesignCoOrdinator from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/ConstructionContractingOperations/DesignCoOrdinator/DesignCoOrdinator.jsx";
import Estimating from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/ConstructionContractingOperations/Estimating/Estimating.jsx";
import General from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/ConstructionContractingOperations/General/General.jsx";
import Planning from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/ConstructionContractingOperations/Planning/Planning.jsx";
import DecorativeFinishing from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DecorativeFinishing/DecorativeFinishing.jsx";
import DemolitionChargehand from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/Demolition/DemolitionChargehand/DemolitionChargehand.jsx";
import DemolitionOperative from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/Demolition/DemolitionOperative/DemolitionOperative.jsx";
import DemolitionPlant from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/Demolition/DemolitionPlant/DemolitionPlant.jsx";
import ExternalWallInsulationBoarder from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/ExternalWallInsulationBoarder/ExternalWallInsulationBoarder.jsx";
import RoomInRoof from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/RoomInRoof/RoomInRoof.jsx";
import ParkHomes from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/ParkHomes/ParkHomes.jsx";
import InternalInsulationWalls from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/InternalInsulationWalls/InternalInsulationWalls.jsx";
import InsulatingFramedSections from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/InsulatingFramedSections/InsulatingFramedSections.jsx";
import HybridWall from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/HybridWall/HybridWall.jsx";
import ExternalWallInsulationFinisher from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/ExternalWallInsulationBoarderAndFinisher/ExternalWallInsulationBoarderAndFinisher.jsx";
import ExternalWallInsulationBoarderAndFinisher from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInsulationAndBuildingTreatments/ExternalWallInsulationFinisher/ExternalWallInsulationFinisher.jsx";
import ComplexSuspendedCeilingSystems from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInteriorSystem/ComplexSuspendedCeilingSystems/ComplexSuspendedCeilingSystems.jsx";
import ServiceOperablePartitionWalls from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInteriorSystem/ServiceOperablePartitionWalls/ServiceOperablePartitionWalls.jsx";
import StretchedCeilingFixing from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInInteriorSystem/StretchedCeilingFixing/StretchedCeilingFixing.jsx";
import PlasteringSolid from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInPlastering/PlasteringSolid/PlasteringSolid.jsx";
import PlasteringFibrous from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInPlastering/PlasteringFibrous/PlasteringFibrous.jsx";
import DiplomaInPlantInstallationsTowerCrane from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInPlantInstallationsTowerCrane/DiplomaInPlantInstallationsTowerCrane.jsx";
import DiplomaInPlantInstallationsHoist from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInPlantInstallationsHoist/DiplomaInPlantInstallationsHoist.jsx";
import DiplomaInWallAndFloorTiling from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWallAndFloorTiling/DiplomaInWallAndFloorTiling.jsx";
import DiplomaInTrowelOccupation from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInTrowelOccupation/DiplomaInTrowelOccupation.jsx";
import DiplomaInStonemasonryMemorialMasonry from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInStonemasonryMemorialMasonry/DiplomaInStonemasonryMemorialMasonry.jsx";
import DiplomaInStonemasonryBankerMasonry from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInStonemasonryBankerMasonry/DiplomaInStonemasonryBankerMasonry.jsx";
import ArchitecturalJoinery from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/ArchtitecturalJoinery/ArchtitecturalJoinery.jsx";
import ShoplifittingBenchWork from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/ShoplifittingBenchWork/ShoplifittingBenchWork.jsx";
import ShoplifittingSiteWork from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/ShoplifittingSiteWork/ShoplifittingSiteWork.jsx";
import SiteCarpentry from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/SiteCarpentry/SiteCarpentry.jsx";
import StructurePostAndBeam from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/StructurePostAndBeam/StructurePostAndBeam.jsx";
import TimberFrameErection from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/TimberFrameErection/TimberFrameErection.jsx";
import WheelWrighting from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInWoodOccupation/WheelWrighting/WheelWrighting.jsx";
import Formwork from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/Formwork/Formwork.jsx";
import HeritageSkillsFullySupportedLeadAndHardMetalRoofing from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/HeritageSkillsFullySupportedLeadAndHardMetalRoofing/HeritageSkillsFullySupportedLeadAndHardMetalRoofing.jsx";
import HeritageSkillsMason from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/HeritageSkillsMason/HeritageSkillsMason.jsx";
import HeritageSkillsRoofSlattingAndTilingOccupations from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/HeritageSkillsRoofSlattingAndTilingOccupations/HeritageSkillsRoofSlattingAndTilingOccupations.jsx";
import HeritageSkillsWoodOccupations from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/HeritageSkillsWoodOccupations/HeritageSkillsWoodOccupations.jsx";
import MachineryMaintenance from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/MachineryMaintenance/MachineryMaintenance.jsx";
import OccupationWorkSupervision from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/OccupationWorkSupervision/OccupationWorkSupervision.jsx";
import DynamicPileTesting from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/TestingInspectingAndExaminationOccupation/DynamicPileTesting/DynamicPileTesting.jsx";
import LeakDetectionInwaterproofSystems from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/TestingInspectingAndExaminationOccupation/LeakDetectionInwaterproofSystems/LeakDetectionInwaterproofSystems.jsx";
import DiplomaInSteelFixingOccupation from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInSteelFixingOccupation/DiplomaInSteelFixingOccupation.jsx";
import RoofSlater from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInRoofingOccupation/RoofSlater";
import RoofSlaterAndTiler from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInRoofingOccupation/RoofSlaterAndTiler";
import RoofTiler from "./screens/NVQ_Courses/NVQ_Level_3/Level3_NVQ_Diploma/DiplomaInRoofingOccupation/RoofTiler";
import AwardLevel1 from "./screens/NVQ_Courses/NVQ_Level_1/Level1Award.jsx";
import FutureWorkplaceInConstructionEnvironment from "./screens/NVQ_Courses/NVQ_Level_2/Level2Awards/FutureWorkplaceinConstructionEnvironment/FutureWorkplaceinConstructionEnvironment.jsx";
import GreenAmbassadorWithinTheConstructionIndustry from "./screens/NVQ_Courses/NVQ_Level_2/Level2Awards/GreenAmbassadorwithintheConstructionIndustry/GreenAmbassadorwithintheConstructionIndustry.jsx";
import GreenAmbassadorWithinTheRetrofitSector from "./screens/NVQ_Courses/NVQ_Level_2/Level2Awards/GreenAmbassadorwithintheRetrofitSector/GreenAmbassadorwithintheRetrofitSector.jsx";
import RainscreenCladdingInstallation from "./screens/NVQ_Courses/NVQ_Level_2/Level2Awards/RainscreenCladdingInstallation/RainscreenCladdingInstallation.jsx";
import RecognisingDampMouldAndCondensationBuildings from "./screens/NVQ_Courses/NVQ_Level_2/Level2Awards/RecognisingDampMouldAndCondensationBuildings/RecognisingDampMouldAndCondensationBuildings.jsx";
import ConstructionPlantOperations from "./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/ConstructionPlantOperations /ConstructionPlantOperations .jsx";
import ArticulatedDrawbarVehicle from "./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/Driving/ArticulatedDrawBarVehicle/ArticulatedDrawBarVehicle.jsx";
import MotorCyclePedalCycle from "./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/Driving/MotorCyclePedalCycle/MotorCyclePedalCycle.jsx";
import RigidVehicle from "./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/Driving/RigidVehicle/RigidVehicle.jsx";
import Van from "./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/Driving/Van/Van.jsx";
import Forklift from "./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/Forklift/Forklift.jsx";
import HighwaysMaintenanceExcavation from "./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/HighwaysMaintenanceExcavation/HighwaysMaintenanceExcavation.jsx";
import HighwaysMaintenanceKerbs from "./screens/NVQ_Courses/NVQ_Level_2/Level2Certificates/HighwaysMaintenanceKerbs/HighwaysMaintenanceKerbs.jsx";
import DamageRepairAndResurfacing from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/AssociatedIndustrialService/DamageRepairAndResurfacing/DamageRepairAndResurfacing.jsx";
import FacadePreservation from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/AssociatedIndustrialService/FacadePreservation/FaqsAndContactForm.jsx";
import InstallingProtectiveComponents from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/AssociatedIndustrialService/InstallingProtectiveComponents/InstallingProtectiveComponents.jsx";
import WaterJetting from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/AssociatedIndustrialService/WaterJetting/WaterJetting.jsx";
import ColdFormedSteelFrame from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/FittedInteriors/FittedInteriors";
import ErectionOfPrecastConcreteCladding from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/ConstructionUsing/ErectionOfPrecastConcreteCladding/ErectionOfPrecastConcreteCladding";
import ErectionOfPrecastConcreteFlooring from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/ConstructionUsing/ErectionOfPrecastConcreteFlooring/ErectionOfPrecastConcreteFlooring";
import FittedInteriors from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/FittedInteriors/FittedInteriors";
import CavityBarrier from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/InteriorSystems/CavityBarrier/CavityBarrierInstallation.jsx";
import CeilingFixing from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/InteriorSystems/CeilingFixing/CeilingFixing.jsx";
import DryLiningFinishing from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/InteriorSystems/DryLining/DryLiningFinishing.jsx";
import DryLiningBoarder from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/InteriorSystems/DryLiningBoarder/DryLiningBoarder.jsx";
import NonHazardousWaste from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/NonHazardousWaste/NonHazardousWaste.jsx";
import MovementGuide from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/MovementGuide/MovementGuide";
import LoadingAndSecurity from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/LoadingAndSecurity/LoadingAndSecurity";
import LayingAndDistributing from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/LayingAndDistributing/LayingAndDistributing";
import ForkLiftTrucks from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/ForkLiftTrucks/ForkLiftTrucks";
import Extracting from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/Extracting/Extracting";
import Excavating from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQDiploma/PlantOperations/Excavating/Excavating";
import CranesandSpecialistLifting from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/CranesandSpecialistLifting/CranesandSpecialistLifting";
import Compacting from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/Compacting/Compacting";
import Attachments from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/Attachments/Attachments";
import OperationsGuide from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/OperationsGuide/OperationsGuide";
import Processing from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/Processing/Processing";
import RoadAndRail from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/RoadAndRail/RoadAndRail";
import TransportingLoads from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/TransportingLoads/TransportingLoads";
import SweepingCleaningClearing from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/SweepingCleaningClearing/SweepingCleaningClearing";
import WorkPlatforms from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/PlantOperation/WorkPlatforms/WorkPlatforms";
import Excavation from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/Excavation/Excavation";
import ExcavationAndReinstatement from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/ExcavationAndReinstatement/ExcavationAndReinstatement";
import LayingkerbsAndChannels from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/LayingkerbsAndChannels/LayingkerbsAndChannels";
import LocateAndExcavateUtilities from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/LocateAndExcavateUtilities/LocateAndExcavateUtilities";
import MaintenanceResponseTeam from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/MaintenanceResponseTeam/MaintenanceResponseTeam";
import ManualRoadBuildingFlexiblePavement from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/ManualRoadBuildingFlexiblePavement/ManualRoadBuildingFlexiblePavement";
import ModularPavementConstruction from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/ModularPavementConstruction/ModularPavementConstruction";
import Reinstatement from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/Reinstatement/Reinstatement";
import RoadSweepingMachine from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/RoadSweepingMachine/RoadSweepingMachine";
import StreetIronWork from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/RoadbuilidingAndMaintenance/StreetIronWork/StreetIronWork";
import BlindsAndSolarShadingSystems from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/BlindsAndSolarShadingSystems/BlindsAndSolarShadingSystems";
import IndustrialStorageSystemsInspection from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/IndustrialStorageSystemsInspection/IndustrialStorageSystemsInspection";
import IndustrialStorageSystemsMaintenance from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/IndustrialStorageSystemsMaintenance/IndustrialStorageSystemsMaintenance";
import JointSealantApplications from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/JointSealantApplications/JointSealantApplications";
import LoadingBayEquipmentInstallation from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/LoadingBayEquipmentInstallation/LoadingBayEquipmentInstallation";
import PointOfPurchase from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/PointOfPurchase/PointOfPurchase";
import RoofLiningSystems from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/SpecialistInstallationOccupations/RoofLiningSystems/RoofLiningSystems";
import WallAndFloorTiling from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/WallAndFloorTiling/WallAndFloorTiling";
import GreenRoofInstaller from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/WaterProofMembrane/GreenRoofInstaller/GreenRoofInstaller";
import GreenRoofSeeding from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/WaterProofMembrane/GreenRoofSeeding/GreenRoofSeeding";
import GreenRoofSupport from "./screens/NVQ_Courses/NVQ_Level_2/Level2NVQCertificates/WaterProofMembrane/GreenRoofSupport/GreenRoofSupport.jsx";
axios.defaults.baseURL = API_BASE_URL;
axios.defaults.headers = { "ngrok-skip-browser-warning": true };
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/" element={<Home />} index={true} />
      <Route path="/nvq-level-1" element={<NvqProducts />} />
      <Route path="/nvq-level-2" element={<NvqProducts />} />
      <Route path="/nvq-level-3" element={<NvqProducts />} />
      <Route path="/nvq-level-4" element={<NvqProducts />} />
      <Route path="/nvq-level-5" element={<NvqProducts />} />
      <Route path="/nvq-level-6" element={<NvqProducts />} />
      <Route path="/nvq-level-7" element={<NvqProducts />} />
      <Route path="/nvq-level-4-demolition" element={<DemolitionLevel4 />} />
      <Route path="/nvq-level-4-retrofit" element={<RetrofitLevel4 />} />
      <Route
        path="/nvq-level-1-award-health&safety"
        element={<AwardLevel1 />}
      />
      <Route
        path="/level-2-future-workplace-in-construction-environment"
        element={<FutureWorkplaceInConstructionEnvironment />}
      />
      <Route
        path="/level-2-green-ambassador-within-the-construction-industry"
        element={<GreenAmbassadorWithinTheConstructionIndustry />}
      />
      <Route
        path="/level-2-green-ambassador-within-the-retrofit-sector"
        element={<GreenAmbassadorWithinTheRetrofitSector />}
      />
      <Route
        path="/level-2-rainscreen-cladding-installation"
        element={<RainscreenCladdingInstallation />}
      />
      <Route
        path="/level-2-recognising-damp-mould-and-condensation-buildings"
        element={<RecognisingDampMouldAndCondensationBuildings />}
      />
      <Route
        path="/level-2-construction-plant-operations"
        element={<ConstructionPlantOperations />}
      />
      <Route
        path="/level-2-articulated-drawbar-vehicle"
        element={<ArticulatedDrawbarVehicle />}
      />
      <Route
        path="/level-2-motor-cycle-pedal-cycle"
        element={<MotorCyclePedalCycle />}
      />
      <Route path="/level-2-rigid-vehicle" element={<RigidVehicle />} />
      <Route path="/level-2-van" element={<Van />} />
      <Route path="/level-2-forklift" element={<Forklift />} />
      <Route
        path="/level-2-highways-maintenance-excavation"
        element={<HighwaysMaintenanceExcavation />}
      />
      <Route
        path="/level-2-highways-maintenance-kerbs"
        element={<HighwaysMaintenanceKerbs />}
      />
      <Route
        path="/level-2-damage-repair-and-resurfacing"
        element={<DamageRepairAndResurfacing />}
      />
      <Route
        path="/level-2-facade-preservation"
        element={<FacadePreservation />}
      />
      <Route
        path="/level-2-installing-protective-components"
        element={<InstallingProtectiveComponents />}
      />
      <Route path="/level-2-water-jetting" element={<WaterJetting />} />
      <Route
        path="/level-2-cold-formed-steel-frame-assembly"
        element={<ColdFormedSteelFrame />}
      />
      <Route
        path="/level-2-erection-precast-concrete-cladding"
        element={<ErectionOfPrecastConcreteCladding />}
      />
      <Route
        path="/level-2-erection-precast-concrete-flooring"
        element={<ErectionOfPrecastConcreteFlooring />}
      />
      <Route path="/level-2-fitted-interiors" element={<FittedInteriors />} />
      <Route path="/level-2-cavity-barrier" element={<CavityBarrier />} />{" "}
      <Route path="/level-2-ceiling-fixing" element={<CeilingFixing />} />{" "}
      <Route
        path="/level-2-dry-lining-finishing"
        element={<DryLiningFinishing />}
      />{" "}
      <Route
        path="/level-2-dry-lining-boarder"
        element={<DryLiningBoarder />}
      />
      <Route
        path="/level-2-non-hazardous-waste"
        element={<NonHazardousWaste />}
      />
      <Route path="/level-2-movement-guide" element={<MovementGuide />} />
      <Route
        path="/level-2-loading-and-security"
        element={<LoadingAndSecurity />}
      />
      <Route
        path="/level-2-laying-and-distributing"
        element={<LayingAndDistributing />}
      />
      <Route path="/level-2-fork-lift-trucks" element={<ForkLiftTrucks />} />
      <Route path="/level-2-extracting" element={<Extracting />} />
      <Route path="/level-2-excavating" element={<Excavating />} />
      <Route
        path="/level-2-cranes-and-specialist-lifting"
        element={<CranesandSpecialistLifting />}
      />
      <Route path="/level-2-compacting" element={<Compacting />} />
      <Route path="/level-2-attachments" element={<Attachments />} />
      <Route path="/level-2-operations-guide" element={<OperationsGuide />} />
      <Route path="/level-2-processing" element={<Processing />} />
      <Route path="/level-2-road-and-rail" element={<RoadAndRail />} />
      <Route
        path="/level-2-sweeping-cleaning-clearing"
        element={<SweepingCleaningClearing />}
      />
      <Route
        path="/level-2-transporting-loads"
        element={<TransportingLoads />}
      />
      <Route path="/level-2-work-platforms" element={<WorkPlatforms />} />
      <Route path="/level-2-excavation" element={<Excavation />} />
      <Route
        path="/level-2-excavation-and-reinstatement"
        element={<ExcavationAndReinstatement />}
      />
      <Route
        path="/level-2-laying-kerbs-and-channels"
        element={<LayingkerbsAndChannels />}
      />
      <Route
        path="/level-2-locate-and-excavate-utilities"
        element={<LocateAndExcavateUtilities />}
      />
      <Route
        path="/level-2-maintenance-response-team"
        element={<MaintenanceResponseTeam />}
      />
      <Route
        path="/level-2-manual-road-building-flexible-pavement"
        element={<ManualRoadBuildingFlexiblePavement />}
      />
      <Route
        path="/level-2-modular-pavement-construction"
        element={<ModularPavementConstruction />}
      />
      <Route path="/level-2-reinstatement" element={<Reinstatement />} />
      <Route
        path="/level-2-road-sweeping-machine"
        element={<RoadSweepingMachine />}
      />
      <Route path="/level-2-street-iron-work" element={<StreetIronWork />} />
      <Route
        path="/level-2-blinds-and-solar-shading-systems"
        element={<BlindsAndSolarShadingSystems />}
      />
      <Route
        path="/level-2-industrial-storage-systems-inspection"
        element={<IndustrialStorageSystemsInspection />}
      />
      <Route
        path="/level-2-industrial-storage-systems-maintenance"
        element={<IndustrialStorageSystemsMaintenance />}
      />
      <Route
        path="/level-2-joint-sealant-applications"
        element={<JointSealantApplications />}
      />
      <Route
        path="/level-2-loading-bay-equipment-installation"
        element={<LoadingBayEquipmentInstallation />}
      />
      <Route path="/level-2-point-of-purchase" element={<PointOfPurchase />} />,
      <Route
        path="/level-2-roof-lining-systems"
        element={<RoofLiningSystems />}
      />
      <Route
        path="/level-2-wall-and-floor-tiling"
        element={<WallAndFloorTiling />}
      />
      <Route
        path="/level-2-green-roof-installer"
        element={<GreenRoofInstaller />}
      />
      <Route
        path="/level-2-green-roof-seeding"
        element={<GreenRoofSeeding />}
      />
      <Route
        path="/level-2-green-roof-support"
        element={<GreenRoofSupport />}
      />
      <Route
        path="/nvq-level-4-traditional-And-heritage-building"
        element={<TraditionalandHeritageBuildingLevel4 />}
      />
      <Route
        path="/nvq-level-4-highways-maintenance-and-repair"
        element={<HighwaysMaintenanceandRepairLevel4 />}
      />
      <Route
        path="/nvq-level-4-residential-development"
        element={<ResidentiaDevelopmentLevel4 />}
      />
      <Route path="/nvq-level-4-awards" element={<AwardLevel4 />} />
      <Route path="/nvq-level-4-tunnelling" element={<TunnellingLevel4 />} />
      <Route
        path="/nvq-level-4-supervising-lifts"
        element={<SupervisingLiftsLevel4 />}
      />
      <Route
        path="/nvq-level-4-building-and-civil-engineering"
        element={<CivilandBuildingEngineeringLevel4 />}
      />
      <Route path="/sssts-online-course" element={<SsstsCourse />} />
      <Route
        path="/sssts-online-course-detail/:id"
        element={<SsstsCourseDetail />}
      />
      <Route
        path="/sssts-refresher-course"
        element={<SsstsRefresherCourse />}
      />
      <Route
        path="/sssts-refresher-course-detail/:id"
        element={<SsstsRefresherCourseDetail />}
      />
      <Route path="/smsts-online-course" element={<SmstsCourse />} />
      <Route
        path="/smsts-online-course-detail/:id"
        element={<SmstsCourseDetail />}
      />
      <Route
        path="/smsts-refresher-course"
        element={<SmstsRefresherCourse />}
      />
      <Route
        path="/smsts-refresher-course-detail/:id"
        element={<SmstsRefresherCourseDetail />}
      />
      <Route
        path="/nvq-level-7-construction-senior-management"
        element={<ConstructionSeniorManagement />}
      />
      {/* -construction-senior-management */}
      <Route path="/nvq-level-3-health&safety" element={<OhsLevel3 />} />
      <Route
        path="/nvq-level-6-health&safety-practice"
        element={<OhsLevel6 />}
      />
      <Route path="/business-solution" element={<HealthSafetyConsultancy />} />
      <Route
        path="/nvq-level-7-Strategic-Health-and-Safety-Leadership-and-Management"
        element={<OhsLevel7 />}
      />
      <Route
        path="/nvq-level-6-residential-dvelopment-pathway"
        element={<ResidentiaDevelopment />}
      />
      <Route
        path="/nvq-level-6-traditional-and-heritage-building"
        element={<TraditionalandHeritageBuilding />}
      />
      <Route
        path="/nvq-level-6-building-and--civil-engireering-Pathway"
        element={<CivilandBuildingEngineering />}
      />
      <Route
        path="/level-6-construction-in-site-management-demolition"
        element={<Demolition />}
      />
      <Route
        path="/level-6-construction-in-site-management-highways-maintenance-and-repair"
        element={<HighwaysMaintenanceandRepair />}
      />
      <Route
        path="/level-6-construction-in-site-management-senior-site-inspection"
        element={<SeniorSiteInspection />}
      />
      <Route
        path="/level-6-construction-in-site-management-contracting-operations-management"
        element={<ContractingOperationsManagement />}
      />
      <Route
        path="/level-3-award-in-preparing-for-the-future-workplace"
        element={<AwardLevel3 />}
      />
      <Route
        path="/level-3-pipework-components"
        element={<PipeworkComponents />}
      />
      <Route
        path="/level-3-moving-loads-construction"
        element={<MovingLoadsConstruction />}
      />
      <Route path="/level-3-plant-maintenance" element={<PlantMaintenance />} />
      <Route
        path="/level-3-shoring-and-pipe-laying"
        element={<ShoringAndPipeLaying />}
      />
      <Route path="/level-3-surveying" element={<Surveying />} />
      <Route
        path="/level-3-site-technical-support"
        element={<SiteTechnicalSupport />}
      />
      <Route
        path="/level-3-temporary-suspended-access"
        element={<TemporarySuspendedAccess />}
      />
      <Route path="/level-3-steeplejacking" element={<Steeplejacking />} />
      <Route
        path="/level-3-scaffolding-and-offshore"
        element={<ScaffoldingAndOffshore />}
      />
      <Route
        path="/level-3-lighting-protective-system"
        element={<LightingProtectiveSystem />}
      />
      <Route
        path="/level-3-lighting-protection-engineer"
        element={<LightingProtectionEngineer />}
      />
      <Route
        path="/level-3-rain-screen-wall-cladding-system"
        element={<RainScreenWallCladdingSystem />}
      />
      <Route
        path="/level-3-roof-and-wall-sheeting"
        element={<RoofAndWallSheeting />}
      />
      <Route path="/level-3-buying" element={<Buying />} />
      <Route path="/level-3-roof-slater" element={<RoofSlater />} />
      <Route path="/level-3-roof-tiler" element={<RoofTiler />} />
      <Route
        path="/level-3-roof-slater-tiler"
        element={<RoofSlaterAndTiler />}
      />
      <Route
        path="/level-3-design-coordinator"
        element={<DesignCoOrdinator />}
      />
      <Route path="/level-3-estimating" element={<Estimating />} />
      <Route path="/level-3-general" element={<General />} />
      <Route path="/level-3-planning" element={<Planning />} />
      <Route
        path="/level-3-welding-construction"
        element={<WeldingConstruction />}
      />
      <Route
        path="/level-3-decorative-finishing"
        element={<DecorativeFinishing />}
      />
      <Route
        path="/level-3-demolition-chargehand"
        element={<DemolitionChargehand />}
      />
      <Route
        path="/level-3-demolition-operative"
        element={<DemolitionOperative />}
      />
      <Route path="/level-3-demolition-plant" element={<DemolitionPlant />} />
      <Route
        path="/level-3-external-wall-insulation-boarder"
        element={<ExternalWallInsulationBoarder />}
      />
      <Route path="/level-3-room-in-roof" element={<RoomInRoof />} />
      <Route path="/level-3-park-homes" element={<ParkHomes />} />
      <Route
        path="/level-3-internal-insulation-walls"
        element={<InternalInsulationWalls />}
      />
      <Route
        path="/level-3-insulating-framed-sections"
        element={<InsulatingFramedSections />}
      />
      <Route path="/level-3-hybrid-wall" element={<HybridWall />} />
      <Route
        path="/level-3-external-wall-insulation-finisher"
        element={<ExternalWallInsulationFinisher />}
      />
      <Route
        path="/level-3-external-wall-insulation-boarder-and-finisher"
        element={<ExternalWallInsulationBoarderAndFinisher />}
      />
      <Route
        path="/level-3-complex-suspended-ceiling-systems"
        element={<ComplexSuspendedCeilingSystems />}
      />
      <Route
        path="/level-3-service-operable-partition-walls"
        element={<ServiceOperablePartitionWalls />}
      />
      <Route
        path="/level-3-stretched-ceiling-fixing"
        element={<StretchedCeilingFixing />}
      />
      <Route path="/level-3-plastering-solid" element={<PlasteringSolid />} />
      <Route
        path="/level-3-plastering-fibrous"
        element={<PlasteringFibrous />}
      />
      <Route
        path="/level-3-diploma-in-plant-installations-tower-crane"
        element={<DiplomaInPlantInstallationsTowerCrane />}
      />
      <Route
        path="/level-3-diploma-in-plant-installations-hoist"
        element={<DiplomaInPlantInstallationsHoist />}
      />
      <Route
        path="/level-3-diploma-in-wall-and-floor-tiling"
        element={<DiplomaInWallAndFloorTiling />}
      />
      <Route
        path="/level-3-diploma-in-trowel-occupation"
        element={<DiplomaInTrowelOccupation />}
      />
      <Route
        path="/level-3-diploma-in-stonemasonry-memorial-masonry"
        element={<DiplomaInStonemasonryMemorialMasonry />}
      />
      <Route
        path="/level-3-diploma-in-stonemasonry-banker-masonry"
        element={<DiplomaInStonemasonryBankerMasonry />}
      />
      <Route
        path="/level-3-diploma-in-steel-fixing-occupation"
        element={<DiplomaInSteelFixingOccupation />}
      />
      <Route
        path="/level-3-demolition-chargehand"
        element={<DemolitionChargehand />}
      />
      <Route
        path="/level-3-demolition-operative"
        element={<DemolitionOperative />}
      />
      <Route path="/level-3-demolition-plant" element={<DemolitionPlant />} />
      <Route
        path="/level-3-decorative-finishing"
        element={<DecorativeFinishing />}
      />
      <Route
        path="/level-3-architectural-joinery"
        element={<ArchitecturalJoinery />}
      />
      <Route
        path="/level-3-shoplifitting-bench-work"
        element={<ShoplifittingBenchWork />}
      />
      <Route
        path="/level-3-shoplifitting-site-work"
        element={<ShoplifittingSiteWork />}
      />
      <Route path="/level-3-site-carpentry" element={<SiteCarpentry />} />
      <Route
        path="/level-3-structure-post-and-beam"
        element={<StructurePostAndBeam />}
      />
      <Route
        path="/level-3-timber-frame-erection"
        element={<TimberFrameErection />}
      />
      <Route path="/level-3-wheel-wrighting" element={<WheelWrighting />} />
      <Route path="/level-3-formwork" element={<Formwork />} />
      <Route
        path="/level-3-heritage-skills-fully-supported-lead-and-hard-metal-roofing"
        element={<HeritageSkillsFullySupportedLeadAndHardMetalRoofing />}
      />
      <Route
        path="/level-3-heritage-skills-mason"
        element={<HeritageSkillsMason />}
      />
      <Route
        path="/level-3-heritage-skills-roof-slatting-and-tiling-occupations"
        element={<HeritageSkillsRoofSlattingAndTilingOccupations />}
      />
      <Route
        path="/level-3-heritage-skills-wood-occupations"
        element={<HeritageSkillsWoodOccupations />}
      />
      <Route
        path="/level-3-machinery-maintenance"
        element={<MachineryMaintenance />}
      />
      <Route
        path="/level-3-occupation-work-supervision"
        element={<OccupationWorkSupervision />}
      />
      <Route
        path="/level-3-dynamic-pile-testing"
        element={<DynamicPileTesting />}
      />
      <Route
        path="/level-3-leak-detection-inwaterproof-systems"
        element={<LeakDetectionInwaterproofSystems />}
      />
      <Route
        path="/level-5-award-in-preparing-for-the-future-workplace"
        element={<AwardLevel5 />}
      />
      <Route
        path="/level-5-nvq-diploma-in-controlling-lifting-planning-lifts"
        element={<PlanningLifts />}
      />
      <Route path="/nvq-level-6-tunneling" element={<Tunnelling />} />{" "}
      <Route path="/nvq-level-6-retrofit" element={<Retrofit />} />{" "}
      <Route path="/citb-courses" element={<CitbCourses />} />
      <Route path="/cscs-cards" element={<CSCSCardPage />} />
      <Route path="/cart-checkout" element={<CartScreen />} />
      <Route path="/user-checkout" element={<CustomerDetails />} />
      <Route path="/stripe-checkout" element={<CheckoutForm />} />
      <Route path="/return" element={<Return />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/cookies-policy" element={<CookiesPolicyPage />} />
      <Route path="/term-and-conditions" element={<TermsAndConditionsPage />} />
    </Route>
  )
);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
if (process.env.NODE_ENV !== "development") {
}

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          <CartProvider>
            <RouterProvider router={router} />
          </CartProvider>
        </QueryClientProvider>
      </ToastProvider>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
