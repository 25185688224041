import React from "react";
import backgroundImage from "../../assets/homePage/transparantGirl.svg";
import icon1 from "../../assets/homePage/Accredited Programmes_1 1.svg";
import icon2 from "../../assets/homePage/career_1 1.svg";
import icon3 from "../../assets/homePage/learning_1 1.svg";

const Hero = () => {
  return (
    <section
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.9)), url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className="relative px-4 sm:px-8 lg:px-16 pt-6 pb-0 mt-32 sm:mt-28">
      {/* Main content */}
      <div className="relative mx-auto max-w-screen-xl flex flex-col items-start">
        {/* Title and description */}
        <div className="max-w-2xl text-left mb-4">
          <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-[#313232] leading-tight">
            Empowering Your Future with
            <br />
            <span className="text-[#ff3131]">
              Accredited Training & Qualifications
            </span>
          </h1>
          <p className="mt-4 text-sm sm:text-base md:text-lg text-gray-600">
            M2HSE offers flexible, industry-recognized courses designed to help
            you achieve your career aspirations in Health & Safety,
            Construction, and beyond.
          </p>

          {/* Call-to-action buttons */}
          <div className="flex flex-col p-2 sm:flex-row items-center justify-start gap-4 mt-2">
            {/* Trustpilot Widget */}
            <div
              className="trustpilot-widget"
              data-locale="en-GB"
              data-template-id="53aa8807dec7e10d38f59f32"
              data-businessunit-id="65e09121938229973209b5ac"
              data-style-height="150px"
              data-style-width="250px">
              <a
                href="https://uk.trustpilot.com/review/m2hse.co.uk"
                target="_blank"
                rel="noopener noreferrer">
                Trustpilot
              </a>
            </div>

            {/* Book a Free Consultation Button */}
            <a
              href="/contact-us"
              className="px-6 py-3 text-sm sm:text-md font-semibold text-white bg-[#ff3131] rounded-full hover:bg-red-600 shadow-lg">
              Book a Free Consultation
            </a>
          </div>
        </div>

        {/* Feature cards */}
        <div className="w-full flex justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-4xl">
            {[
              {
                icon: icon1,
                title: "Accredited Programmes",
                description: "Our courses meet the highest industry standards.",
              },
              {
                icon: icon2,
                title: "Qualifications You Can Trust",
                description: "Gain confidence with recognized certifications.",
              },
              {
                icon: icon3,
                title: "Exceptional Feedback",
                description: "Loved by learners, proven by results.",
              },
            ].map((card, index) => (
              <div
                key={index}
                className="bg-[#FF3131] h-[120px] sm:h-[140px] rounded-t-xl relative flex flex-col justify-end p-4 text-white overflow-hidden shadow-md">
                {/* Icon positioned top-right */}
                <img
                  src={card.icon}
                  alt={card.title}
                  className="absolute top-3 right-4 w-10 h-10"
                />
                {/* Text at bottom-left */}
                <div className="text-left">
                  <p className="font-semibold text-base">{card.title}</p>
                  <p className="text-sm">{card.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
