import React, { useEffect, useState } from "react";
import { useCart } from "../../CartContext";
import { ImCross } from "react-icons/im";
import cartImage from "../../assets/cartPage/empty-cart-red.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CourseCart = () => {
  const { removeFromCart, clearCart, getTotalPrice } = useCart();
  const [emiDuration, setEmiDuration] = useState(null);
  const [emiPlans, setEmiPlans] = useState([]);

  // Function to calculate total price including VAT
  const calculateTotalWithVAT = () => {
    const totalPrice = getTotalPrice();
    const vat = totalPrice * 0.2; // 20% VAT
    return totalPrice + vat;
  };
  const cartItems = JSON.parse(localStorage.getItem("cart")) || {};
  // Function to calculate EMI price based on selected duration
  const getEmiPrice = () => {
    const totalWithVAT = calculateTotalWithVAT();
    if (emiDuration.duration) {
      return (totalWithVAT / emiDuration.duration).toFixed(2);
    }
    // console.log(emiDuration);
    return totalWithVAT.toFixed(2);
  };

  const navigate = useNavigate();

  const handleCheckout = () => {
    if (emiDuration?.duration) {
      cartItems.emiPlan = emiDuration;
    }
    navigate("/user-checkout", { state: { cartItems } });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/emi/plans");
        setEmiPlans(response.data.plans);
        // setEmiPlans(response.data.plans); // Assuming the API response structure is { success: true, plans: [...]}
      } catch (error) {
        console.error("Error fetching EMI plans:", error);
      }
    };
    if (cartItems.isEmi) fetchData();
  }, [cartItems.isEmi]);

  const [couponCode, setCouponCode] = useState("");

  const handleCouponChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handleApplyCoupon = () => {
    if (couponCode.trim() === "") {
      alert("Please enter a coupon code.");
      return;
    }
  };

  return (
    <div className="bg-[#0C1524] mt-20 text-gray-800 py-12 px-6 sm:px-12 lg:px-20">
      {cartItems?.items.length > 0 ? (
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-3 gap-12 mt-12">
          {/* Left Section */}
          <div className="lg:col-span-2 space-y-6">
            {cartItems?.items?.map((item) => (
              <div
                key={item._id}
                className="bg-white rounded-lg shadow-md flex justify-between items-center p-6">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">
                    {item.itemName}
                  </h3>
                  <p className="text-gray-700">Price: £{item.price}</p>
                </div>
                <button
                  onClick={() => removeFromCart(item)}
                  className="text-red-600 hover:text-red-800">
                  <ImCross size={16} />
                </button>
              </div>
            ))}

            {/* Coupon Input */}
            <div className="flex items-center gap-4">
              <input
                type="text"
                placeholder="Discount Code"
                value={couponCode}
                onChange={handleCouponChange}
                className="w-1/2 px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-red-500"
              />
              <button
                className="bg-[#FF3131] text-white px-6 py-2 rounded-md hover:bg-red-700 transition"
                onClick={handleApplyCoupon}
                disabled={couponCode.trim() === ""} // Disable button if input is empty
              >
                Apply
              </button>
            </div>

            {/* EMI Options */}
            <div className="mt-8">
              <h4 className="text-lg font-semibold text-gray-300 mb-4">
                Pay with a Monthly Fee
              </h4>
              <div className="grid grid-cols-3 gap-4">
                {emiPlans.map((emi) => (
                  <div
                    key={emi?.duration}
                    className={`p-4 text-center rounded-lg border transition cursor-pointer hover:bg-red-100 ${
                      emiDuration?.duration === emi?.duration
                        ? "bg-red-600 text-white"
                        : "bg-white text-gray-800"
                    }`}
                    onClick={() => setEmiDuration(emi)}>
                    <p className="font-medium cursor-pointer">
                      {emi.duration} Month (£
                      {(calculateTotalWithVAT() / emi.duration).toFixed(2)}{" "}
                      /month)
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">
              Order Summary
            </h3>
            <hr className="mb-6" />

            <div className="space-y-4 text-gray-800">
              <div className="flex justify-between">
                <p>Price:</p>
                <p>£{getTotalPrice().toFixed(2)}</p>
              </div>
              <div className="flex justify-between">
                <p>Discount:</p>
                <p>£0.00</p>
              </div>
              <div className="flex justify-between">
                <p>VAT (20%):</p>
                <p>£{(getTotalPrice() * 0.2).toFixed(2)}</p>
              </div>
              <div className="flex justify-between font-bold">
                <p>Total Amount:</p>
                <p>£{calculateTotalWithVAT().toFixed(2)}</p>
              </div>
              {emiDuration != null && (
                <div className="flex justify-between font-bold">
                  <p>Monthly installments({emiDuration?.duration} Months):</p>
                  <p>£{getEmiPrice()}</p>
                </div>
              )}
            </div>

            <button
              className="w-full bg-green-600 text-white py-3 rounded-lg mt-6 hover:bg-green-700 transition"
              onClick={handleCheckout}>
              Continue to Checkout
            </button>
          </div>
        </div>
      ) : (
        <div className="text-center mt-32">
          <img
            src={cartImage}
            alt="Empty Cart"
            className="mx-auto w-2/3 max-w-sm"
          />
          {/* <p className="text-gray-300 mt-6">Your cart is currently empty.</p> */}
          <a
            href="/"
            className="mt-8 inline-block bg-red-600 text-white py-3 px-6 rounded-lg hover:bg-red-700 transition">
            Continue Shopping
          </a>
        </div>
      )}
    </div>
  );
};

export default CourseCart;
