import React from "react";
import Hero from "./hero";
import WhatsNew from "./whatsNew";
import EmpoweringSuccess from "./EmpoweringSuccess/EmpoweringSuccess";
import Accreditation from "./Accreditations/Accreditations";
import image3 from "../../assets/homePage/logos/Ofqual_logo_2018.svg.png";
import image1 from "../../assets/homePage/logos/WhatsApp Image 2024-07-08 at 6.41.09 PM.jpeg";
import image2 from "../../assets/homePage/logos/citb.jpg";

const images = [image1, image2, image3];

const Home = () => {
  return (
    <div>
      <Hero />
      <WhatsNew />
      <Accreditation images={images} />
      <EmpoweringSuccess />
      {/* <Blogs /> */}
      {/* <ReviewStories /> */}

      <div className="bg-[#0D1521] py-6 px-4 sm:px-8 rounded-xl shadow-lg mx-auto max-w-4xl">
        <h2 className="text-2xl md:text-3xl font-bold text-white mb-4 text-center">
          See What Our Customers Say
        </h2>
        <div className="text-center">
          <div
            class="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="539ad0ffdec7e10e686debd7"
            data-businessunit-id="65e09121938229973209b5ac"
            data-style-height="350px"
            data-style-width="100%"
            data-theme="dark"
            data-stars="1,2,3,4,5"
            data-review-languages="en">
            <a
              href="https://uk.trustpilot.com/review/m2hse.co.uk"
              target="_blank"
              rel="noopener noreferrer">
              Trustpilot
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
