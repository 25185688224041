import React, { useState, useRef, useEffect } from "react";
import navbarContent from "../../assets/data/navbar.json";
import logo from "../../assets/logo.png";
import { RiArrowDropDownLine } from "react-icons/ri";
import { TbArrowBadgeRightFilled } from "react-icons/tb";
import { useCart } from "../../CartContext";

import { FaShoppingCart, FaBars, FaTimes } from "react-icons/fa";
import SearchComponent from "./SearchComponent";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { getCartCount } = useCart();
  const totalItems = getCartCount();
  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const [openDropdown, setOpenDropdown] = useState(null);
  const [openNestedDropdown, setOpenNestedDropdown] = useState(null);
  const dropdownRefs = useRef({});

  const handleClickOutside = (event) => {
    if (
      !Object.values(dropdownRefs.current).some(
        (ref) => ref && ref.contains(event.target)
      )
    ) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Main Navbar Links
  const renderNavbarLinks = () => (
    <div class="lg:block hidden">
      <ul className="flex space-x-4 justify-between">
        {Object.keys(navbarContent).map((key, index) => (
          <li
            key={key}
            className="relative"
            ref={(ref) => (dropdownRefs.current[key] = ref)}
          >
            <a
              href={navbarContent[key].path || ""}
              className="flex items-center h-10 px-2 font-semibold rounded cursor-pointer hover:bg-gray-100 transition-colors duration-100 font-poppins text-[#313232] text-[16px] border-b-2 border-transparent hover:border-[#FF3131]"
              onClick={(e) => {
                if (!navbarContent[key].path) {
                  e.preventDefault(); // Prevent navigation
                }
                setOpenDropdown(openDropdown === key ? null : key);
              }}
            >
              <span>{key}</span>
              {navbarContent[key].dropdown && <RiArrowDropDownLine />}
            </a>

            {openDropdown === key && navbarContent[key].dropdown && (
              <DropdownMenu items={navbarContent[key].dropdown} />
            )}
          </li>
        ))}
      </ul>
    </div>
  );

  // Mobile Navbar Links
  const renderMobileNavbarLinks = () => (
    <div className="text-white">
      {/* Navbar Links */}
      <div>
        <ul className="flex flex-col lg:flex-row lg:space-x-4 justify-between">
          {Object.keys(navbarContent).map((key) => (
            <li
              key={key}
              className="relative"
              ref={(ref) => (dropdownRefs.current[key] = ref)}
            >
              {/* Main menu item */}
              <a
                href={navbarContent[key].path || ""}
                className={`flex items-center h-10 px-2 cursor-pointer font-poppins text-white text-[16px] ${
                  openDropdown === key
                    ? "bg-[#FF3131] text-white hover:bg-[#FF3131]"
                    : "hover:border-[#FF3131]"
                }`}
                onClick={(e) => {
                  if (!navbarContent[key].path) {
                    e.preventDefault(); // Prevent navigation
                  }
                  setOpenDropdown(openDropdown === key ? null : key);
                }}
              >
                <span>{key}</span>

                {navbarContent[key].dropdown && (
                  <span className="ml-auto pl-2">
                    {/* Plus and Minus Icons */}
                    {openDropdown === key ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-4 w-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    )}
                  </span>
                )}
              </a>

              {/* Dropdown Menu */}
              {openDropdown === key && navbarContent[key].dropdown && (
                <div className="left-0 mt-2 bg-slate-100 shadow-lg rounded-lg w-full z-10">
                  <ul className="space-y-2">
                    {Object.keys(navbarContent[key].dropdown).map(
                      (item, index) => (
                        <li key={index}>
                          <a
                            href={navbarContent[key].dropdown[item].path}
                            className="flex px-4 py-2 text-gray-700 hover:bg-gray-100"
                          >
                            <span>{item}</span>

                            {/* Child dropdown handling */}
                            {navbarContent[key].dropdown[item].dropdown && (
                              <span
                                className="ml-auto pl-2 cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault(); // Prevent link navigation only for the SVG
                                  e.stopPropagation(); // Stop the event from bubbling
                                  setOpenNestedDropdown(
                                    openNestedDropdown === item ? null : item
                                  );
                                }}
                              >
                                {openNestedDropdown === item ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M5 12h14"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 4.5v15m7.5-7.5h-15"
                                    />
                                  </svg>
                                )}
                              </span>
                            )}
                          </a>

                          {/* Nested Dropdown Menu */}
                          {openNestedDropdown === item &&
                            navbarContent[key].dropdown[item].dropdown && (
                              <div className="mt-2 bg-gray-100 shadow-lg rounded-lg w-full z-10">
                                <ul className="space-y-2">
                                  {Object.keys(
                                    navbarContent[key].dropdown[item].dropdown
                                  ).map((nestedItem, nestedIndex) => (
                                    <li key={nestedIndex}>
                                      <a
                                        href={
                                          navbarContent[key].dropdown[item]
                                            .dropdown[nestedItem].path
                                        }
                                        className="block px-4 py-2 text-gray-700 hover:bg-gray-200"
                                      >
                                        {nestedItem}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );

  return (
    <header className=" text-[#0C1524] fixed z-50 pb-4 bg-white w-[100%]">
      {/* Top Bar */}
      <div className="flex flex-wrap justify-between items-center px-4 py-2 bg-[#101c34] text-sm">
        <div className="flex items-center space-x-4 md:space-x-10 w-full md:w-1/2 justify-center md:justify-start">
          <span className="text-white text-xs sm:text-sm">
            <span className="text-[#FF3131]">Email: </span>{" "}
            <a href="mailto:sales@m2hse.co.uk" className="hover:underline">
              sales@m2hse.co.uk
            </a>
          </span>
          <span className="text-white text-xs sm:text-sm">
            <span className="text-[#FF3131]">Contact:</span>{" "}
            <a href="tel:02080599944" className="hover:underline">
              02080-599944
            </a>
          </span>
        </div>
        <div className="w-full md:w-1/2">
          <SearchComponent />
        </div>
      </div>

      {/* Main Navbar */}
      <div className="flex justify-between items-center px-4 py-2 md:py-2">
        {/* Logo */}
        <div className="flex items-center space-x-4">
          <a href="/">
            <img src={logo} alt="Logo" className="w-auto h-10" />
          </a>
        </div>

        {/* Desktop Navbar */}
        <nav className="hidden lg:flex items-center space-x-6">
          <ul className="flex items-center space-x-6">{renderNavbarLinks()}</ul>
          <a
            href="/cart-checkout"
            className="flex items-center space-x-2 relative group"
          >
            <FaShoppingCart className="text-2xl text-black hover:text-gray-700 transition-colors" />
            <span className="absolute -top-2 -right-3 bg-red-600 text-white text-xs font-bold w-5 h-5 rounded-full flex items-center justify-center">
              {totalItems}
            </span>
          </a>
        </nav>

        {/* Mobile Menu Icon */}
        <div className="lg:hidden flex items-center">
          <button
            onClick={handleMobileMenuToggle}
            className="text-xl focus:outline-none"
          >
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>

      {/* Mobile Navbar */}
      {isMobileMenuOpen && (
        <nav className="block lg:hidden bg-[#101c34] text-white">
          <ul className="space-y-4 p-4">{renderMobileNavbarLinks()}</ul>
          <div className="p-4">
            <a
              href="/cart-checkout"
              className="flex items-center justify-between text-white px-4 py-3 "
            >
              <div className="flex items-center space-x-3">
                <FaShoppingCart className="text-2xl" />
              </div>
              <div className="relative">
                <span className="absolute -top-2 -right-2 text-white text-lg font-bold w-5 h-5 rounded-full flex items-center justify-center">
                  {totalItems}
                </span>
              </div>
            </a>
          </div>
        </nav>
      )}
    </header>
  );
};

const DropdownMenu = ({ items }) => {
  const [hoveredItem, setHoveredItem] = useState(null);

  return (
    <div className="bg-white shadow-md rounded-sm border  text-sm absolute top-auto left-0 min-w-full w-56 z-30 mt-0">
      <ul className="list-reset">
        {Object.keys(items).map((key) => (
          <li
            key={key}
            className="relative group"
            onMouseEnter={() => setHoveredItem(key)}
            onMouseLeave={() => setHoveredItem(null)}
          >
            <a
              href={items[key].path || "#"}
              className={`px-4 py-2 flex w-full items-start rounded-sm no-underline justify-between text-black hover:bg-[#FF3131] hover:text-white ${
                items[key].comingSoon ? "cursor-not-allowed" : ""
              }`}
              onClick={(e) => {
                if (items[key].path === "#") {
                  e.preventDefault(); // Prevent navigation
                }
              }}
            >
              <span className="group-hover:hidden">{key}</span>
              {!items[key].comingSoon && (
                <span className="hidden group-hover:inline">{key}</span>
              )}
              {items[key].comingSoon && (
                <span className="hidden group-hover:inline">
                  {"Coming soon..."}
                </span>
              )}
              {/* Arrow for nested dropdown */}
              {items[key].dropdown && hoveredItem === key && (
                <span>
                  <TbArrowBadgeRightFilled />
                </span>
              )}
              {/* Nested dropdown that opens on hover */}
              {items[key].dropdown && hoveredItem === key && (
                <div className="hidden group-hover:block absolute top-0 left-[96%] bg-white rounded border w-56 z-40 ml-2">
                  <DropdownMenu items={items[key].dropdown} />
                </div>
              )}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navbar;
